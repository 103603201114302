import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import config from '../Components/config';
import {AuthContext} from './AuthContext'



export const ProfileContext = createContext(null);
const Profile =  (props) => {

  const cookies = new Cookies();
  const navigate = useNavigate();
  const Auth = useContext(AuthContext);

    /**
     * login function
     */
    const isEnrolled = (courseId, stateCallback) => {
        //return true;
        let userId = Auth.getUserId();
        let res =  Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('is_enrolled_course?tracker='+Auth.getTracker(), {courseId, userId}).then( (res) => {
            let enrolled = res?.data?.payload?.isEnrolled || 0;            
            let isEnrolledValue = false;                        
            if(enrolled == 1){
                isEnrolledValue = true;
                
            }            
            stateCallback(isEnrolledValue);
        });
    }

    const enrollCourse = async(courseId) => {
        let userId = Auth.getUserId();
        let res =  await Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('enroll_course_student?tracker='+Auth.getTracker(), {courseId, userId})
        let status = res?.data?.status || 0; 
        return status;
    }

    const unenrollCourse = async(courseId) => {
        let userId = Auth.getUserId();
        let res =  await Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('unenroll_course_student?tracker='+Auth.getTracker(), {courseId, userId})
        let status = res?.data?.status || 0; 
        return status;
    }    

    const getUserChildren = async(async=false)=>{
        let userId = Auth.getUserId();
        let res = null;
        if(async){
            res =  await Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('get_user_children/'+userId, {})
            return res;
        }
        else{
            return Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('get_user_children/'+userId, {}); // as promise function
        }
    }

    const getProfileData = async(async=false) => {
        let userId = Auth.getUserId();
        let res = null;
        if(async){
            res =  await Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('get_user_data/'+userId, {})
            return res;
        }
        else{
            return Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('get_user_data/'+userId, {}); // as promise function
        }        
    }

    const removeChild = async(async=false,dataObj)=>{
        let userId = Auth.getUserId();
        let res = null;
        if(async){
            res =  await Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('remove_child/'+userId, dataObj)
            return res;
        }
        else{
            return Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('remove_child/'+userId, dataObj); // as promise function
        }
    }    

    const canAddChild = async(dispatch) => {
        let userId = Auth.getUserId();
        let res =  await Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('can_add_children/'+userId, {})
        let status = res?.data?.payload?.status || 0; 
        let max_child_to_add = res?.data?.payload?.total_childs_can_add || 0; 
        if(status === 'active_parent'){
            dispatch({type:'SET_CAN_ADD_CHILD', data:{value:true, max_child_to_add}});
        }
        else{
            dispatch({type:'SET_CAN_ADD_CHILD', data:{value:false, max_child_to_add}});
        }
    }

    const getUserCouresSummary = async(userId, courseId=0, async=false) => {
        let res = null;
        if(async){
            res =  await Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('get_user_courses_summary/'+userId+'/'+courseId, {})
            return res;
        }
        else{
            return Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('get_user_courses_summary/'+userId+'/'+courseId, {}); // as promise function
        }
    }


    const getEnrolledCourses = async(userId,async=false) => {
        let res = null;
        if(async){
            res =  await Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('dashboard_enrolled_courses/'+userId, {})
            return res;
        }
        else{
            return Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('dashboard_enrolled_courses/'+userId, {}); // as promise function
        }
    }    

    const getModulesCertificates = async(courseId, userId, async=false) => {
        let res = null;
        if(async){
            res = await Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('modules_certificates', {userId,courseId})
            return res?.data?.payload || []; 
        }
        else{
            return Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('modules_certificates', {userId,courseId}); // as promise function
        }
    }


    const getModuleCertificate = async(courseId, moduleId, userId, async=false) => {
        let res = null;
        if(async){
            res = await Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('module_certificate', {userId,courseId,moduleId})
            return res?.data?.payload || []; 
        }
        else{
            return Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('module_certificate', {userId,courseId,moduleId}); // as promise function
        }
    }    


    /**
     * register function
     */
     const updateUserProfile = async (values) => {
    
        let response = null;
        const userId = Auth.getUserId();
        values['language'] = getSelectedLanguage();
        await Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('updateUserProfile/'+userId,values).then((res)=>{            
            if(res.data.status == 1){ // success register
                cookies.set('user_name', values.first_name+' '+values.last_name, { path: '/' , maxAge: 172800});
                response = res.data;
          
            }
            else{
                response = res.data;
                // 3 => email used before
            }
        }).catch(()=>{

        }); 

        return response;

  }     


    /**
     * register function
     */
     const updateChildProfile = async (values) => {
    
        let response = null;
        const userId = Auth.getUserId();
        values['language'] = getSelectedLanguage();
        await Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('updateChildProfile/'+userId,values).then((res)=>{            
            if(res.data.status == 1){ // success register
                response = res.data;
          
            }
            else{
                response = res.data;
                // 3 => email used before
            }
        }).catch(()=>{

        }); 

        return response;

  }   

  const getSelectedLanguage = () => {
    if(localStorage.getItem('lang')){
      return localStorage.getItem('lang');
    }
  
    return config.defualtLang;
  } 

    return (
        <ProfileContext.Provider value={{isEnrolled, enrollCourse, unenrollCourse, getUserChildren, getUserCouresSummary, removeChild, canAddChild, getProfileData, updateUserProfile, getModulesCertificates, getModuleCertificate, updateChildProfile, getEnrolledCourses}}>
            {props.children}
        </ProfileContext.Provider>
    )
}

export default Profile;