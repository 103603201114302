import {
    isMobile,
    isTablet,
    deviceType,
    osName,
    osVersion,
    browserName,
    browserVersion,
    engineName,
    engineVersion,
    mobileVendor,
    mobileModel
} from 'react-device-detect';

async function getIpAddress(){
    //let res = await axios.get("https://api.ipify.org/?format=json");
    let ip = ''//res?.data?.ip || '';
    return ip;
}
export const getDeviceInfo = async(stringify=false) => {
    let ipAddress = await getIpAddress();

    let info = {isTablet, isMobile, deviceType, osName, osVersion, browserName, browserVersion, engineName, engineVersion, mobileModel, mobileVendor, ipAddress}; 

    if(stringify){
        return JSON.stringify(info);
    }
    return info;
}

