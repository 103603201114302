
import { useContext } from "react";
import { useEffect, useState } from "react";
import {NavLink} from 'react-router-dom';
import { AuthContext } from "../context/AuthContext";
import { UserContext } from "../context/languageContext";
import { removeQueryStringParam, ReplaceTagStringWithElement } from "../helpers/functions";
import MessageModal from "./modals/messageModal";
import {getDeviceInfo} from '../helpers/deviceInfo';
import { useNavigate } from 'react-router-dom';

const SharedComponent = () => {

    const nav = useNavigate();
    const keywords = useContext(UserContext);
    const Auth = useContext(AuthContext);
    const searchParams = new URLSearchParams(document.location.search);
    const [popup,setPopup] = useState({title:'',body:''});
    const isRegPopup = searchParams.get('reg_popup');
    const lang = searchParams.get('lang') || '';
    const action = searchParams.get('action') || '';
    const [email, setEmail] = useState(0);
    const [waitingToResend, setWaitingToResend] = useState(0);
    const [messageDetails, setMessageDetails] = useState({title:'',message:''});
  

    const showGewneralMessage = (title='',message='') => {

            setMessageDetails({title,message});
            window.$('#general-modal').modal('show');   
      
    }

    useEffect(() => {

        if(lang){
            let queryString = removeQueryStringParam('lang','',false,true);
            //console.log(document.location.pathname+queryString);
            keywords.setLanguage(lang,false,false,document.location.pathname+queryString);
        }

        if(action == 'login'){
            setTimeout(() => {
                window.$('#login').modal('show');
              }, 3000);            
        }
        if(action == 'survey_verify'){
            const action_email = searchParams.get('email') || '';
            const action_token = searchParams.get('token') || '';
            const action_redirect = searchParams.get('redirect') || '';
            if(action_email && action_token){
                Auth.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'},{},'survey').post('survey_verify',{email:action_email, token:action_token}).then( res => {
                    window.$('#login').modal('show');
                    if(action_redirect){
                        localStorage.setItem('forceRedAfterLogin',action_redirect)
                    }
                }).catch( err => {

                });
            }

        }

        getDeviceInfo().then(res => {
            const browserName = res.browserName.toLowerCase();
            const osName = res.osName.toLowerCase();
            //console.log(browserName,' ',osName);
            //console.log(browserName.includes('safari'));
            if(browserName.includes('safari') || osName.includes('linux')){
                require('../assets/css/safari.css');
            }
        });
        
        let userAgent = navigator.userAgent;
        let browserName;

        
      
        if(userAgent.match(/chrome|chromium|crios/i)){
            browserName = "chrome";
          }else if(userAgent.match(/firefox|fxios/i)){
            browserName = "firefox";
            window.$('.field label').css('top', '-17px');
            window.$('.reg-field label').css('top', '-17px');
            window.$('.reg-field-tel label').css('top', '-17px');
            if(window.$(window).width() <= 992){
            window.$('.form-select').css('height', '49px');
            }
            window.$('#mobile').css('padding', '8px 105px !important');
            window.$('.reg-input').css('padding', '8px 20px !important');
          } 

         

        if(isRegPopup && isRegPopup==1 && Auth.isLogged){
            const accType = Auth.getAccountType();
            if(accType == 3){ // children account
                setPopup({title:keywords.message,body:ReplaceTagStringWithElement(keywords.after_reg_message_child, 'navlink', 
                <NavLink onClick={
                    ()=>{
                        setPopup({title:keywords.message,body:keywords.processingDots});
                        Auth.childActivationJourney().then( res => {
                            
                            if(res==true){
                                setPopup({title:keywords.message,body:keywords.success_send_activation_parent})
                                setTimeout(() => {
                                    window.location.href = localStorage.getItem('basename')+"/dashboard";//config.domain+'dashboard';
                                }, 2000);                                 
                            }
                            else{
                                setPopup({title:keywords.message,body:keywords.failed_send_activation_parent});            
                            }
                            
                        });
                    }
                  }/>

                  )});
            } 
            else{



                Auth.getUserEmail().then(res => {
                    if(res.data?.status==1){
                        setEmail(res.data.payload.email);
                        let emailR = res.data.payload.email;

                        keywords.account_not_activated_click3_1 = keywords.account_not_activated_click3_1.replace('%s',emailR)
                        setPopup({title:keywords.message,body:[ReplaceTagStringWithElement(keywords.account_not_activated_click3_1,'nav', 
        
                        <a onClick={
                            ()=>{
                                nav('/profile');
                                window.$('#reg-popup').modal('hide'); 
                            }
                          }/>
        
                          ) ,
        
        
        
                          ReplaceTagStringWithElement(keywords.account_not_activated_click3_2,'nav2', 
        
                          <NavLink onClick={
                              ()=>{
                                  setPopup({title:keywords.message,body:keywords.processingDots});
                                  Auth.sendActivationEmail().then( res => {
                                      
                                      if(res==true){
                                          setPopup({title:keywords.message,body:keywords.success_send_activation});
                                          setTimeout(() => {
                                              window.location.href = localStorage.getItem('basename')+"/dashboard";//config.domain+'dashboard';
                                          }, 2000);                                  
                                      }
                                      else{
                                          setPopup({title:keywords.message,body:keywords.failed_send_activation});            
                                      } 
                                                                  
                                      
                                  });
                              }
                            }/>
          
                            )
                        ]
        
        
                        
                        });
                        
                        



                    }
                })                



                  
            }
                        
            
    
            setTimeout(() => {
                window.$('#reg-popup').modal('show');
            }, 3000);        
        }





        if(isRegPopup && isRegPopup==2 && Auth.isLogged){

                Auth.getUserEmail().then(res => {
                    if(res.data?.status==1){
                        setEmail(res.data.payload.email);
                        let emailR = res.data.payload.email;

                        keywords.account_not_activated_click3_1 = keywords.account_not_activated_click3_1.replace('%s',emailR)
                        setPopup({title:keywords.message,body:[ keywords.account_not_activated_click3_external_1, <br/>, 
        
                          ReplaceTagStringWithElement(keywords.account_not_activated_click3_external_2,'nav', 
        
                          <NavLink onClick={
                              ()=>{
                                  setPopup({title:keywords.message,body:keywords.processingDots});
                                  Auth.sendActivationEmail().then( res => {
                                      
                                      if(res==true){
                                          setPopup({title:keywords.message,body:keywords.success_send_activation});
                                          setTimeout(() => {
                                              window.location.href = localStorage.getItem('basename')+"/dashboard";//config.domain+'dashboard';
                                          }, 2000);                                  
                                      }
                                      else{
                                          setPopup({title:keywords.message,body:keywords.failed_send_activation});            
                                      } 
                                                                  
                                      
                                  });
                              }
                            }/>
          
                            )
                        ]
        
        
                        
                        });
                        
                        



                    }
                })                                          
                        
            
    
            setTimeout(() => {
                window.$('#reg-popup').modal('show');
            }, 3000);        
        }



    },[]);


    return (
        <>
            <MessageModal title={popup.title} id="reg-popup" okLabel="Close">
            {popup.body}
            </MessageModal>  

            <MessageModal title={messageDetails.title} id="general-modal" okLabel="Close">
            {messageDetails.body}
            </MessageModal>                    
        </>
    )
}

export default SharedComponent