import { gtag } from 'ga-gtag';

// console.log('index2.js loaded');
const trafficSrcParamsThirdParty = ['dm'];
const searchParamsThirdParty = new URLSearchParams(document.location.search);
let sourceKeyThirdParty, sourceValThirdParty = '';
for(let i=0;i<trafficSrcParamsThirdParty.length;i++){
  let paramNameThirdParty = trafficSrcParamsThirdParty[i];
  if(searchParamsThirdParty.get(paramNameThirdParty)){
    window.isThirdParty=true;
    break;
  }
}


function getLang()
{
  if(localStorage.getItem('lang'))
     console.log(localStorage.getItem('lang'));
  
}



function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }  




  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  } 
  
  


  function login(){

    let fbStart = setInterval(() => {
        

        if(window.FB){

            // console.log('fb defined');
        window.FB.getLoginStatus(function(response) {
            // checks if user already authorized
            // console.log(response.status+" ===")
                        if (response.status === 'connected') {
                                window.FB.api('me?fields=id,name,email', function(user) {
                                  // console.log(user)
                                         if(user != null) {
                                          //console.log(user)
                                          let lastname = user.name.split(" ")[1] || "";
                                          lastname += lastname && user.name.split(" ")[2] ? " "+user.name.split(" ")[2] : ""; 
                                          if(user.email==undefined || user.email=='undefined')
                                            setCookie('fb_email','',1);
                                          else
                                            setCookie('fb_lname',user.email,1);
        
                                          setCookie('fb_fname',user.name.split(" ")[0],1);
                                          setCookie('fb_lname',lastname,1);
                                                                }
                                    });
        
                        } else {
                          // console.log(response.status )
                          return;
                        }
        
                    });


                clearInterval(fbStart);



                }
                else{

                }

    }, 1000);



} 



function setTagParams(isThirdParty)
{
  if(isThirdParty)
  {
    // console.log(' third')

  }
  else
  {
    // console.log('not third');
      var gtag_view_ad_word = 'AW-11178320075';
      var gtag_view_coversion_id = '2GEhCNipz9gYEMvBntIp';
      var gtag_view_event_name = 'pageview';
      window.dataLayer = window.dataLayer || [];
      //function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      //gtag('send', 'pageview');
      //gtag('config', gtag_view_ad_word);
      /* */ 
      gtag('event', gtag_view_event_name, { 
      'send_to':gtag_view_ad_word+'/'+gtag_view_coversion_id
      });      
      window.dataLayer = window.dataLayer || [];
      //function gtag(){dataLayer.push(arguments);}
    //   window.gtag('js', new Date());
      gtag('config', 'G-KW6FK3Z4V9');
  }
}


// getLang();








if(window.isThirdParty)
{//third party tags
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-KZJ492V');//GTM-WVCCRRPW

}
else
{//beecell tags
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-KZJ492V');
}





    let fbinit = setInterval(() => {
        if(window.FB){
            window.FB.init({appId: '595048781716632',status : true,xfbml  : true, cookie: true ,oauth  : true});        
            clearInterval(fbinit);
        }
    }, 1000);

  







if(getCookie('fb_email') =="" || !getCookie('fb_email') || getCookie('fb_email') == undefined){
  setCookie('fb_email','',1);
  login(); 
  // console.log('facebook')  
}





if(window.isThirdParty)
{
 // <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WVCCRRPW" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>

  // console.log(' third')    
}
else
{
  var tagScript = document.createElement('script')
  tagScript.setAttribute('src', "https://www.googletagmanager.com/gtag/js?id=AW-11178320075")
  tagScript.setAttribute('async', true);
  document.body.appendChild(tagScript)
  var tagScript = document.createElement('script')
  tagScript.setAttribute('src', "https://www.googletagmanager.com/gtag/js?id=G-KW6FK3Z4V9");
  tagScript.setAttribute('async', true);
  document.body.appendChild(tagScript) 
  // console.log('not third')
}




setTagParams(window.isThirdParty);







if(window.isThirdParty)
{
 // <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WVCCRRPW" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  var noscript = document.createElement('noscript')
  var iframe = document.createElement('iframe')
  iframe.setAttribute('src', "https://www.googletagmanager.com/ns.html?id=GTM-KZJ492V");//GTM-WVCCRRPW

  iframe.setAttribute("height", "0")
  iframe.setAttribute("width", "0")
  iframe.setAttribute("style", "display:none;visibility:hidden")
  noscript.appendChild(iframe)
  document.body.appendChild(noscript)    
}
else
{
  //<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KZJ492V"  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  var noscript = document.createElement('noscript')
  var iframe = document.createElement('iframe')
  iframe.setAttribute('src', "https://www.googletagmanager.com/ns.html?id=GTM-KZJ492V")

  iframe.setAttribute("height", "0")
  iframe.setAttribute("width", "0")
  iframe.setAttribute("style", "display:none;visibility:hidden")
  noscript.appendChild(iframe)
  document.body.appendChild(noscript)  
}