import React, { memo, useState, useEffect } from "react";
import {NavLink} from 'react-router-dom';
import logo from '../assets/images/logo.svg' 
import arrowDown from '../assets/images/orange-arrow.svg' 
import Config from '../Components/config'
import {UserContext} from '../context/languageContext'
import {AuthContext} from '../context/AuthContext'
import UserNavbar from "./sections/userNavbar";
import { otherLanguageCourseName } from "../helpers/functions";
import Cookies from "universal-cookie";
import Image from "./elements/image";
// import '../assets/js/navbar.js'
import '../assets/css/courses.css';
import '../assets/css/userNavbar.css';
import ArSurveyIcon from '../assets/images/ar_survey_icon.svg';
import EnSurveyIcon from '../assets/images/en_survey_icon.svg';
import {getDeviceInfo} from '../helpers/deviceInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars , faXmark } from '@fortawesome/free-solid-svg-icons';


const Header=(props)=>{
    const [isCampTheme, setisCampTheme] = useState(false);
    const [isLoadHeader, setisLoadHeader] = useState(true);
    const [hideReg, setHideReg] = useState(false);
    const cookies= new Cookies();
    let isMena = cookies.get('isMena');
    let isVas = cookies.get('isVas') || 0;
    const Language = localStorage.getItem('lang');

    let domain = window.location.origin+'/'
    let menaDomains = Config.menDomains;    
    let vasDomains = Config.vasDomains;  
    // console.log('Domain : ',domain);
     if(!isMena && menaDomains){
        for(let x=0;x<menaDomains.length;x++){
            let domainForMena = menaDomains[x];
            if(domain.includes(domainForMena)){
                cookies.set('isMena', 1, { path: '/' , maxAge: (3600*24*365)});
                isMena = 1;
                break;
            }
        }

     } 
     
     if(!isVas && vasDomains){
        for(let x=0;x<vasDomains.length;x++){
            let domainForVas = vasDomains[x];
            if(domain.includes(domainForVas)){
                cookies.set('isVas', 1, { path: '/' , maxAge: (3600*24*365)});
                isVas = 1;
                break;
            }
        }

     }       

    const lagKeywords = React.useContext(UserContext);     
    const lang = lagKeywords.getSelectedLanguage();

    const Auth = React.useContext(AuthContext); 
    const introCourses = props.sharedCourses;
    //let [introCourses, setCourses] = useState([]);
    const [suggestions,setSuggestions]=useState([]);
    const [searchTextInput,setSearchText] = useState('');
    //console.log(Array.isArray(introCourses))
    if(typeof(introCourses) == 'string'){
    introCourses = JSON.parse(introCourses);    
    }
    useEffect(()=> {
        // alert(document.location.pathname);
        setisCampTheme(document.location.pathname.indexOf(localStorage.getItem('basename')+'/campaignTheme') !== -1 || document.location.pathname == localStorage.getItem('basename')+'/campaignTheme' || document.location.pathname == localStorage.getItem('basename')+'/campaignTheme/' || document.location.pathname == localStorage.getItem('basename')+'/campaign');
        setisLoadHeader(false);
    },[document.location.pathname]);
      
    //console.log(typeof(introCourses))    
    useEffect(()=>{
        //ApiHelper.excuteApi(Config.coursesApi,'get','{}',setCourses);
    },[]        
    ) 

    const [isMobile, setIsMobile] = useState(false);

    useEffect(()=>{

        getDeviceInfo().then(res => {
            setIsMobile(res?.isMobile);
        });  

        if(window.location.pathname == localStorage.getItem('basename')+'/campaign'){
            setHideReg(true);
        }        
        else{
            setHideReg(false);
        }        
    },[window.location.pathname])
    const [isOpen, setIsopen] = useState(false);
    const [isDropdown, setDropdown] = useState(false);
    const [isDropdownLang, setDropdownLang] = useState(false);

    const ToggleSidebar = () => {
        isOpen === true ? setIsopen(false) : setIsopen(true);
        if (window.$( ".sidebar" ).hasClass('active')) {
            window.$('body').removeClass('hide-overflow');
        } else {
            window.$('body').addClass('hide-overflow');
        }
    }

    window.$('.sidebar-link, .submenu-item, .autoCompleteResults .menu-link').on('click', function() {
        ToggleSidebar();
        window.$('body').removeClass('hide-overflow');
    }); 

    window.$('.logout ').on('click', function() {
            window.$('.sidebar, .sidebar-overlay').css('display','none');
    }); 

    const ToggleDropdown = () => {
        isDropdown === true ? setDropdown(false) : setDropdown(true);
        window.$('.arrowImg').toggleClass('rotate');
        window.$('.submenu-item').on('click', function() {
            window.$('.sidebar').removeClass('active');
            window.$('.sidebar-overlay').removeClass('active');
        });      
    }

    let searchText='';

    const onChangeHandler=(serachValue)=>{
        let matches=[];
        if(serachValue.length > 0)
        {
            var ht = (window.innerHeight - 150);
            window.$('.suggestionContainerMob .allSearch').css('height', ht);
            window.$('.suggestionContainerMob .allSearch').css('overflow','auto');

            window.$('.sd-body').css('overflow','hidden');
            matches=introCourses.filter(cours=>{
                const regex=new RegExp(`${serachValue}`,"gi");
                if(cours.categoryid != -1 && cours.categoryid != -2 && cours.visible==1 && cours.displayname.match(regex))
                    return cours.displayname.match(regex)
            })
    
        } else{
        window.$('.suggestionContainerMob .allSearch').css('height','auto');
        window.$('.sd-body').css('overflow','auto');
        }       
        setSearchText(serachValue)
        setSuggestions(matches);
       // console.log(matches);
    }

    const handleOnClickSuggestion=(serachValue)=>
    {
        setSearchText(serachValue);
        setSuggestions([]);
    }

    const search = (e) => {
       var Language = localStorage.getItem('lang');
       
        window.$( ".searchBtn" ).toggleClass( "closeSearch" );
        window.$( ".inputSearch" ).toggleClass( "squareSearch" );
        window.$( ".close-search" ).css( "display" , "block" );
        window.$( ".searchBtn" ).css( "display" , "none" );

        if ( window.$( '.searchBtn' ).hasClass( 'closeSearch' ) ) {
            window.$( '.inputSearch' ).focus();
            window.$( '.searchIcon' ).focus();
            // window.$( '.login' ).animate( {
            //     opacity: 0
            // }, 200 );
            // window.$( ' .dashborad, .prices, .courses' ).animate( {
            //     opacity: 0
            // }, 200 );
          if (Auth.isLogged){
            window.$( ' .dashborad, .prices, .courses' ).animate( {
                opacity: 0
            }, 200 );
          }else if (!Auth.isLogged){
            window.$( '.login' ).animate( {
                opacity: 0
            }, 200 );
            window.$( ' .dashborad, .prices, .courses' ).animate( {
                opacity: 1
            }, 200 );
          }
            if (Language == 'en'){
            // window.$( '.about, .prices, .courses' ).animate( {right: '70px' });
            window.$( '.inputSearch' ).animate({ marginLeft:'-40px'});
            }else if (Language == 'ar'){
            // window.$( '.about, .prices, .courses' ).animate( {left: '70px' });
            window.$( '.inputSearch' ).animate({ marginRight:'-90px'});
            }
            window.$( ".close-search" ).css( "display" , "block" );
            if (Auth.isLogged){
                window.$( ' .dashborad, .prices, .courses' ).css( 'visibility','hidden');

            }else if(!Auth.isLogged){
                window.$( '.login' ).css( 'visibility','hidden');
            }
            // window.$( '.login' ).css( 'visibility','hidden');
            // window.$( ' .dashborad, .prices, .courses' ).css( 'visibility','hidden');

        } else {
            window.$( '.inputSearch' ).val( '' );
            window.$( '.inputSearch' ).blur();
            setTimeout( function () {
                window.$( '.login' ).animate( {
                    opacity: 1
                }, 200 );
                window.$( '  .dashborad, .prices, .courses' ).animate( {
                    opacity: 1
                }, 200 );
            }, 700 );
            window.$( '.search-mawaheb-container' ).html( '' );
            window.$( '.search-mawaheb-container' ).fadeOut( 50 );
            if (Language == 'en'){
            window.$( '.inputSearch' ).animate( {marginLeft: '0'});
            // window.$( '.about, .prices, .courses' ).animate( {right: '0px' });
            }else if (Language == 'ar'){
            window.$( '.inputSearch' ).animate( {marginRight: '0'});
            // window.$( '.about, .prices, .courses' ).animate( {left: '0px' });
            }
            window.$( '.login' ).css( 'visibility','visible');
            window.$( ' .dashborad, .prices, .courses' ).css( 'visibility','visible');
            window.$( ".close-search" ).css( "display" , "none" );
            window.$( '.close-search' ).animate( {display: 'none' },200);
            window.$( ".searchBtn" ).css( "display" , "block" );
        }
        e.stopPropagation();
    }

    window.$(document).ready(function() {
        window.$(window).on('load', function() { 
            if(window.$(window).width() <= 992){
                window.$( ".sd-body" ).css( "max-height" , "calc(100vh - 100px)" );
            }
        });

    window.$('#se_search-btn.se_search-coll , .search-title').on('click', function() {
        window.$(".search-title").css("opacity","0");
        window.$(".search-title").css("display","none");
        window.$(".se_search-coll").removeClass("se_search-coll");
        window.$("#se_search-box").focus();

    //     if(window.$(window).width() <= 467){
    //     setTimeout( function () {
    //         window.$( '.logo' ).animate( {
    //             opacity: 0
    //         }, 200 );
    //     }, 200 );
    // }
    });
    
    window.$("#se_search-box").blur(function() {
        window.$('.sd-body').css('overflow','auto');

        window.$('.suggestionContainerMob .allSearch').css('height','auto');
        window.$("#se_search-box, #se_search-btn").addClass("se_search-coll");
        window.$(".search-title").css("display","block");

        setTimeout( function () {
            window.$( '.search-title' ).animate( {
                // display: 'block' ,                
                opacity: 1
            }, 800 );
        }, 800 );
    })
    window.$('.register-now a').on('click', function() {
        // window.$('.sidebar').removeClass('active');
        window.$('body').removeClass('hide-overflow');
    }); 

    // window.$(document).ready(function() {
    //     window.$('.survey-icon').draggable();
    //   });
    
    if(window.$(window).width() <= 992){
        { Auth.isLogged === false ? (window.$('#burger').css('width','30%')):(window.$('#burger').css('width', '50%')) }
        window.$('.register-now a').on('click', function() {
            //ToggleSidebar();
            window.$('.sidebar').removeClass('active');
            window.$('.sidebar-overlay').removeClass('active');

            window.$('body').removeClass('hide-overflow');
        }); 
    }

    });


    // const getSurveyTrigger = () => {

    //     let surveyLogo = EnSurveyIcon;
    //     if(Language =='ar'){
    //         surveyLogo = ArSurveyIcon;
    //     }

    //     if(isMobile){
    //         return <a href={localStorage.getItem('basename')+`/survey?lang=${Language}&mobile=1`} className="survey-icon"><img src={surveyLogo} alt={lagKeywords.mawaheb} /></a> 
    //     }
    //     else{
    //         return <a href="javascript:" onClick={()=> window.$('#survey_modal').modal('show')} className="survey-icon"><img src={surveyLogo} alt={lagKeywords.mawaheb} /></a> 
    //     }
    // }
    return(
    <>
       {/* {
        !isCampTheme && isLoadHeader == false ? getSurveyTrigger()
          :
          <a href="#" className="hide survey-icon"></a> 
          } */}
     {!isCampTheme && isLoadHeader == false ? 
    <header className="header" id="header" tabIndex="0">
    <div id="overlay"></div>    
            <section className="wrapper container">
                 { !Auth.isLogged && !isVas && hideReg!=true && (

                 <div className="free-reg-btn" style={{width: '30%'}}>
                 <ul className="mb-0 kids_safe_logo">
                    <li className="login kids_safe">
                        <a href="https://www.kidsafeseal.com/certifiedproducts/mawaheb.html" target="_blank">
                        <img border="0" alt="Mawaheb.online is certified by the kidSAFE Seal Program." src="https://www.kidsafeseal.com/sealimage/970657352211535039/mawaheb_small_darktm.png" width={110} height={40} />
                        </a>                            
                    </li>     
            
                    <li className="login hide">
                    <a id={`signup-btn2`} href={localStorage.getItem('basename')+"/userRegistration"} className="login-item login-trigger">{lagKeywords.free_reg}</a>
                    </li>
                 </ul>
                 </div> )  }    
                 <div className="logoContainer">
                    <NavLink to="/"><img fetchpriority="high" src={logo} className="logo" alt={lagKeywords.mawaheb_logo}/></NavLink>
                </div>
                            
                <div className="burger" id="burger" onClick={ToggleSidebar}>
                    <span className="burger-line"></span>
                    <span className="burger-line"></span>
                    <span className="burger-line"></span>
                </div>
               {/* <div className="btn" onClick={ToggleSidebar} >
                    <i className="fa fa-bars"></i>
                </div> */}
                {/* side nav if user logged in */}
               {Auth.isLogged && (
                <div>
                <div className={`sidebar ${isOpen == true ? 'active' : ''}`}>
                <div className="sd-body">

                           { Auth.isLogged === false ?    
                        (
                            <div className={`sd-header ${hideReg ? 'hide-login':''}`}>
                                <h4 className="mb-0">                       
                                    <li className="login">
                                       <a href="#" className="login-item login-trigger" data-target="#login" data-toggle="modal">{lagKeywords.login}</a>
                                    </li>
                                </h4>
                                <div className="btn" onClick={ToggleSidebar}><FontAwesomeIcon icon={faXmark} /></div>
                            </div>
                         )
                       : 
                         (
                       <>
                       <div className="sd-header">
                            <h4 className="mb-0 hello-name">
                                <p className="hello-word">{lagKeywords.hello}</p>
                                <p>{Auth.getUserName()}</p>
                            </h4>
                            <div className="btn" onClick={ToggleSidebar}><FontAwesomeIcon icon={faXmark} /></div>
                       </div>
                           <UserNavbar sharedCourses={props.sharedCourses} ToggleSidebar={ToggleSidebar}/>
                     </>
                        )
                         }
                         </div>
                </div>
                <div className={`sidebar-overlay ${isOpen == true ? 'active' : ''}`} onClick={ToggleSidebar}></div>
                </div>
                )}
                {/* end */}

                {/* usernavbar mob non logged  */}

                {!Auth.isLogged && (
                    <div>
                    <div className={`sidebar ${isOpen == true ? 'active' : ''}`}>
                    <div className="sd-body">
                        <div className="sd-header">
                           { Auth.isLogged === false ?    
                         ''
                       : 
                         (
                       <>
                        <h4 className="mb-0"><p className="hello-word">{lagKeywords.hello}</p><p>{Auth.getUserName()}</p></h4>
                     </>
                        )
                         }

                        <div className="btn" onClick={ToggleSidebar}><FontAwesomeIcon icon={faXmark} /></div>
                        </div>
                       
                            <ul style={{width: '80%'}}> 
                            { Auth.isLogged === false ?    
                        (
                            (!isVas || true) && (
                        //     <li className="login">
                        //         <div className="sd-link">
                        //     <a href="#" className="login-item login-trigger" data-target="#login" data-toggle="modal">{lagKeywords.login}</a>
                        //     </div>
                        //    </li>
                        <li>
                        <div className="sd-link sidebar-link">
                        <a href="#" className="side-item side-i login-trigger" data-target="#login" data-toggle="modal">
                        <div className="side-icon">
                            <img className="sidenav-icon" src={process.env.PUBLIC_URL + "/assets/images/login-icon.svg"} alt={lagKeywords.login_icon} /></div>
                            <p>{lagKeywords.login}</p>
                        </a>
                        </div>
                    </li>
                            )
                         )
                       : 
                         (
                       <>
                        <h4 className="mb-0"><p className="hello-word">{lagKeywords.hello}</p><p>{Auth.getUserName()}</p></h4>
                     </>
                        )
                         }

                                <li>
                                <div className="sd-linkk">
                                <div className="side-search-item">
                                <div className="mob-search">
                                    <form id="se_search-form" style={{display: 'inline'}}>
                                        <input id="se_search-box" 
                                                size="20" 
                                                placeholder={lagKeywords.search} 
                                                className="se_search-coll" 
                                                autoComplete="off" 
                                                type="text" 
                                                onChange={e=>onChangeHandler(e.target.value)} 
                                                name="searchText"
                                                value={searchTextInput} 
                                                onBlur={()=>{
                                                    setTimeout(()=>{
                                                    setSuggestions([])
                                                    },100);
                                                    setSearchText('');
                                                    }}/>
                                        <input id="se_search-btn" className="se_search-coll" type="button" aria-label="searchText"/>
                                        <div className="suggestionContainerMob">
                                            <div className="allSearch">
                                            {suggestions && suggestions.map((sug,i)=>
                                            <div onClick={()=>handleOnClickSuggestion(sug.displayname)} className="autoCompleteResults" key={i}>
                                            <NavLink className="menu-link" to={'course/'+sug.id+'/'+sug.displayname?.trim().split(' ').join('-')}>{sug.displayname}</NavLink>
                                            </div>)
                                            }
                                            </div>
                                        </div>
                                    </form>
                                </div>  
                                <p className="search-title">{lagKeywords.search}</p>
                                </div>  
                                </div>
                                </li> 
                                <li>
                                    <div className="sd-link sidebar-link">
                                    { lang == 'en' ? (
                                    <NavLink onClick={()=>lagKeywords.setLanguage('ar')} className="side-item side-i">
                                    <div className="side-icon">
                                    <img className="sidenav-icon" src={process.env.PUBLIC_URL + "/assets/images/lang-icon.svg"} alt={lagKeywords.language} /></div>
                                    <p style={{marginTop:'-15px'}}>{lagKeywords.ar}</p>
                                    </NavLink>
                                    ) : (
                                    <NavLink onClick={()=>lagKeywords.setLanguage('en')} className="side-item side-i">
                                    <div className="side-icon">
                                    <img className="sidenav-icon" src={process.env.PUBLIC_URL + "/assets/images/lang-icon.svg"} alt={lagKeywords.language} /></div>
                                    <p>{lagKeywords.en}</p>
                                    </NavLink>
                                    )
                                   }
                                    </div>
                                </li>
                                <li>
                                    <div className="sd-link">
                                    <div className="side-item side-i">
                                    <div className="side-icon">
                                        <img className="sidenav-icon" src={process.env.PUBLIC_URL + "/assets/images/courses-icon.svg"} alt={lagKeywords.courses_icon} /></div>
                                        <p onClick={ToggleDropdown}>{lagKeywords.courses}
                                            <div className="side-icon">
                                            <img src={arrowDown} alt="arrow" className="arrowImg" style={{width: "13px", marginLeft: "6px"}} />
                                            </div>
                                        </p>
                                        <div className={` dropdownMenu ${isDropdown == true ? 'active' : ''}`}>
                                            <li className="submenu-item"> 
                                                <NavLink className="menu-link menu-l" to={'courses'}>{lagKeywords.all_courses}</NavLink>
                                            </li>
                                            {introCourses && introCourses.length > 0 && Array.isArray(introCourses) && introCourses.map((introCoursesObj, index) => {
                                                if(introCoursesObj.visible !=1){
                                                    return '';
                                                }
                                                introCoursesObj.displayname = otherLanguageCourseName(introCoursesObj, lagKeywords.getSelectedLanguage());
                                                if(introCoursesObj.categoryid>0)
                                                {

                                                return ( 
                                                    <li className="submenu-item" key={introCoursesObj.id}> 
                                                        <NavLink className="menu-link menu-l" to={'course/'+introCoursesObj.id+'/'+introCoursesObj.displayname?.trim().split(' ').join('-')}>{introCoursesObj.displayname}</NavLink>
                                                    </li>

                                                    )
                                                }
                                                }
                                                
                                            )}   
                                               {/* <li className="submenu-item">   
                                                   <NavLink className="menu-link menu-l" to="/join-our-team"><span style={{color: 'orange'}}>{lagKeywords.join_our_team}</span></NavLink>
                                               </li> */}
                                        </div>
                                    </div>
                                    </div>
                                </li>
                                { (isMena !=1 && isVas !=1) &&
                                <li>
                                    <div className="sd-link sidebar-link">
                                    <NavLink to="/prices" className="side-item side-i">
                                    <div className="side-icon"><img className="sidenav-icon" src={process.env.PUBLIC_URL + "/assets/images/plans-icon.svg"} alt={lagKeywords.payment_paln} /></div>
                                    <p>{lagKeywords.plans_main_menu.charAt(0).toUpperCase()+ lagKeywords.plans_main_menu.slice(1)}</p>
                                    </NavLink>
                                    </div>
                                </li>
                                }

                                {!Auth.isLogged && <li>
                                    <div className="sd-link sidebar-link hide">
                                    <NavLink to="/about" className="side-item side-i">
                                    <div className="side-icon"><img className="sidenav-icon" src={process.env.PUBLIC_URL + "/assets/images/mobNav_about.svg"} alt={lagKeywords.about} /></div>
                                    <p>{lagKeywords.about}</p>
                                    </NavLink>
                                    </div>
                                </li> }

                                {Auth.isLogged &&
                                <li>
                                    <div className="sd-link sidebar-link">
                                    <NavLink to="/dashboard" className="side-item side-i">
                                    <div className="side-icon"><img className="sidenav-icon" src={process.env.PUBLIC_URL + "/assets/images/dashboard.svg"} alt={lagKeywords.dashboard} /></div>
                                    <p>{Auth.getAccountType() ==2 ? lagKeywords.family_dashboard : lagKeywords.dashboard}</p>
                                    </NavLink>
                                    </div>
                                </li>
                                   }
                                <li className="hide">
                                    <div className="sd-link sidebar-link">
                                    <NavLink to="/faqs" className="side-item side-i">
                                    <div className="side-icon">
                                    <img className="sidenav-icon" src={process.env.PUBLIC_URL + "/assets/images/mobNav_faqs.svg"} alt={lagKeywords.faqs} /></div>
                                    <p>{lagKeywords.faqs}</p>
                                    </NavLink>
                                    </div>
                                </li>
                                <li className="hide">
                                    <div className="sd-link sidebar-link">
                                    <NavLink to="/join-our-team" className="side-item side-i">
                                    <div className="side-icon"><img className="sidenav-icon" src={process.env.PUBLIC_URL + "/assets/images/mobNav_joinUs.svg"} alt={lagKeywords.payment_paln} /></div>
                                    <p>{lagKeywords.join_our_team}</p>
                                    </NavLink>
                                    </div>
                                </li>
                                <li className="hide">
                                    <div className="sd-link sidebar-link">
                                    <NavLink to="/TermsConditions" className="side-item side-i">
                                    <div className="side-icon"><img className="sidenav-icon" src={process.env.PUBLIC_URL + "/assets/images/mobNav_terms.svg"} alt={lagKeywords.payment_paln} /></div>
                                    <p>{lagKeywords.terms_condition}</p>
                                    </NavLink>
                                    </div>
                                </li>
                                <li className="hide">
                                    <div className="sd-link sidebar-link">
                                    <NavLink to="/PrivacyPolicy" className="side-item side-i">
                                    <div className="side-icon"><img className="sidenav-icon" src={process.env.PUBLIC_URL + "/assets/images/mobNav_privacy.svg"} alt={lagKeywords.payment_paln} /></div>
                                    <p>{lagKeywords.privacy_policy}</p>
                                    </NavLink>
                                    </div>
                                </li>

                                {/* <li>
                                    <div className="sd-link sidebar-link">
                                    <NavLink to="/PaymentPolicy" className="side-item">
                                    <div className="side-icon"><img className="sidenav-icon" src={process.env.PUBLIC_URL + "/assets/images/mobNav_privacy.svg"} alt={lagKeywords.terms_condition} /></div>
                                    <p>{lagKeywords.payment_policy}</p>
                                    </NavLink>
                                    </div>
                                </li> */}
                                <li className="hide">
                                    <div className="sd-link sidebar-link">
                                    <NavLink to="/ContactUs" className="side-item side-i">
                                    <div className="side-icon"><img className="sidenav-icon" src={process.env.PUBLIC_URL + "/assets/images/mobNav_contactus.svg"} alt={lagKeywords.contact_us} /></div>
                                    <p>{lagKeywords.contact_us}</p>
                                    </NavLink>
                                    </div>
                                </li>


                                {/* <li>
                                    <div className="sd-link">
                                        <div className="side-item side-i">
                                        <div className="side-icon">
                                        <img className="sidenav-icon" src={process.env.PUBLIC_URL + "/assets/images/mobNav_languages.svg"} alt={lagKeywords.language} />
                                        </div>
                                        <p onClick={ToggleDropdownLang}>{lagKeywords.language}
                                            <div className="side-icon">
                                                <img src={arrowDown} className="arrowImgLang" style={{width: "13px", marginLeft: "6px"}} />
                                            </div>
                                        </p>
                                        <ul className={` dropdownMenu ${isDropdownLang == true ? 'active' : ''}`}>
                                            <li className="submenu-item">   
                                            <NavLink style={{justifyContent: 'center !important'}} className={`menu-link menu-l ${lagKeywords.getSelectedLanguage()=='en'?'current-lang' : ''}`} onClick={()=>lagKeywords.setLanguage('en')} href="#">{lagKeywords.en}</NavLink >
                                            <NavLink style={{justifyContent: 'center !important'}} className={`menu-link menu-l ${lagKeywords.getSelectedLanguage()=='ar'?'current-lang' : ''}`} onClick={()=>lagKeywords.setLanguage('ar')} href="#">{lagKeywords.ar}</NavLink >
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className={`sidebar-overlay ${isOpen == true ? 'active' : ''}`} onClick={ToggleSidebar}></div>
                    </div>
)}
                <nav className="navbar" id="navbar">
                    <ul className="menu" id="menu">
                        <li className="menu-item lang-container">
                            <div className="language-changer">
                                <a className={`EN-lang ${lagKeywords.getSelectedLanguage()=='en'?'current-lang' : ''}`} onClick={()=>lagKeywords.setLanguage('en')} href="#">{lagKeywords.en}</a> 
                                <p style={{color:'#999999'}}>/</p>
                                <a className={`AR-lang ${lagKeywords.getSelectedLanguage()=='ar'?'current-lang' : ''}`} onClick={()=>lagKeywords.setLanguage('ar')} href="#">{lagKeywords.ar}</a>
                            </div>
                        </li>
                        
                         { Auth.isLogged ? 
                        <li className="menu-item search-item loggedUserSearch">
                            <div id="contentSearch">
                                <input className="inputSearch" autoComplete="off" type="text" onChange={e=>onChangeHandler(e.target.value)} 
                                name="searchText"
                                value={searchTextInput} 
                                onBlur={()=>{

                                    setTimeout(()=>{
                                    setSuggestions([])
                                    },100);
                                    setSearchText('');
                                    }}/>
                                <button type="reset" className="searchBtn" aria-label="Search" onClick={search} name="searchBtn"></button>
                                <button className="close-search" type="reset" onClick={search}>&times;</button>
                                <div className="suggestionContainer">
                                <div className="allSearch">
                                {suggestions && suggestions.map((sug,i)=>
                                
                                <div onClick={()=>handleOnClickSuggestion(sug.displayname)} className="autoCompleteResults" key={i}>
                                <NavLink className="search-link" to={'course/'+sug.id+'/'+sug.displayname?.trim().split(' ').join('-')}>{sug.displayname}</NavLink>
                                </div>)
                                }
                                </div>
                            </div>
                          </div>                                    
                        </li>
                         : '' }     
                       
                        <li className="menu-item menu-dropdown courses">
                            <span className="menu-link" data-toggle="submenu"><NavLink className='menu-link' style={{Color:'#999999'}} to={'/courses'}>{lagKeywords.courses}</NavLink>
                            <img className="course-arrow" src={arrowDown} style={{width: "13px", marginLeft: "6px", marginBottom: "-5px"}} alt={lagKeywords.mawaheb}/></span>
                            <ul className="submenu">
                                {
                                    introCourses.length ==0 && <center style={{'margin':'20px'}}>{lagKeywords.loading}</center>
                                }
                            {introCourses && introCourses.length > 0 && Array.isArray(introCourses) && introCourses.map((introCoursesObj, index) => {
                                if(introCoursesObj.visible !=1){
                                    return '';
                                }
                                introCoursesObj.displayname = otherLanguageCourseName(introCoursesObj, lagKeywords.getSelectedLanguage());
                                if(introCoursesObj.categoryid > 0)
                                {

                                return ( 
                                    <li className="submenu-item-course" key={introCoursesObj.id}>   
                                       {/* <img style={{width: '27px'}} src={Config.imgDns+ "icon/course_"+introCoursesObj.id+".svg"} alt={introCoursesObj.displayname} /> */}
                                       <Image style={{width: '27px', height: '27px'}} src={Config.imgDns+ "icon/course_"+introCoursesObj.id+".svg"} alt={  lang == 'en' ? introCoursesObj.displayname + lagKeywords.icon : lagKeywords.icon +introCoursesObj.displayname} />
                                       <NavLink className="menu-link" to={'course/'+introCoursesObj.id+'/'+introCoursesObj.displayname?.trim().split(' ').join('-')}>{introCoursesObj.displayname}</NavLink>
                                    </li>
                                    )
                                }
                            }
                        )}    
                                {/* <li className="submenu-item-course">   
                                    <NavLink to={'join-our-team'} className="menu-link">{lagKeywords.join_our_team}</NavLink>
                                </li> */}
                            </ul>
                        </li>
                        { (isMena !=1 && isVas !=1) &&
                        <li className="menu-item prices">
                            <NavLink className="menu-link"to="prices">{lagKeywords.plans_main_menu.charAt(0).toUpperCase()+ lagKeywords.plans_main_menu.slice(1)}</NavLink>
                        </li>
                        }
                        <li className="menu-item dashboard">
                            {Auth.isLogged && <NavLink className="menu-link"to={"dashboard"}>{lagKeywords.dashboard}</NavLink>}
                        </li>
                       { Auth.isLogged === false ?    
                        <li className="menu-item kids_safe">
                        <a href="https://www.kidsafeseal.com/certifiedproducts/mawaheb.html" target="_blank">
                            <img border="0" alt="Mawaheb.online is certified by the kidSAFE Seal Program." src="https://www.kidsafeseal.com/sealimage/970657352211535039/mawaheb_small_darktm.png" width={110} height={40} />
                        </a>                        
                        </li>
                        : 
                        ''                        
                        }
                         { Auth.isLogged === false ? 
                        <li className="menu-item search-item">
                            <div id="contentSearch">
                                <input className="inputSearch" autoComplete="off" type="text" onChange={e=>onChangeHandler(e.target.value)} 
                                name="searchText"
                                value={searchTextInput} 
                                aria-label="Search"
                                onBlur={()=>{

                                    setTimeout(()=>{
                                    setSuggestions([])
                                    },100);
                                    setSearchText('');
                                    }}/>
                                <button type="reset" className="searchBtn" aria-label="Search" onClick={search} name="SearchBtn"></button>
                                <button className="close-search" type="reset" onClick={search}>&times;</button>
                                <div className="suggestionContainer">
                                <div className="allSearch">
                                {suggestions && suggestions.map((sug,i)=>
                                
                                <div onClick={()=>handleOnClickSuggestion(sug.displayname)} className="autoCompleteResults" key={i}>
                                <NavLink className="search-link" to={'course/'+sug.id+'/'+sug.displayname?.trim().split(' ').join('-')}>{sug.displayname}</NavLink>
                                </div>)
                                }
                                </div>
                            </div>
                          </div>                                    
                        </li>
                        : '' }
                        { Auth.isLogged === false ?    
                        (
                            isVas? 
                        <li className={`menu-item login ${hideReg ? 'hide-login':''}`}><a href="#" className="login-item login-trigger" data-target="#login" data-toggle="modal">{lagKeywords.login}</a></li>
                        :
                        <>
                
                        <li className={`menu-item login ${hideReg ? 'hide-login':''}`}><a id={`login-btn1`} href="#" className="login-trigger web-login" data-target="#login" data-toggle="modal">{lagKeywords.login}</a></li>
                        <li className={`menu-item login ${hideReg ? 'hide-login':''}`}><a id={`signup-btn1`} href={localStorage.getItem('basename')+"/userRegistration"} className="web-register">{lagKeywords.register}</a></li>

                        </>
                        )
                        : 
                        (
                        <>
                        {/* <li className="menu-item"><a href="#" className="login-item login-trigger" onClick={Auth.logout}>{lagKeywords.logout}</a></li> */}
                        <li className="menu-item user-loggedin"><p className="hello-word">{lagKeywords.hello}</p><p>{Auth.getUserName()}</p></li>
                        <li className="menu-item user-loggedin">
                            {/* <i className="fa fa-bars burger-nav" onClick={ToggleSidebar}></i> */}
                            <FontAwesomeIcon icon={faBars} onClick={ToggleSidebar} className="burger-nav"/>
                        </li>

                        </>
                        )
                        }
                        {/* <a href="javascript:void(0)" className="closebtn" id="close_sideNav">&times;</a> */}
                        {/*
                        <button onClick={ () => {let req = Auth.authorizedRequest().post('test_auth_function')}} >Test Auth</button>
                        */}
                     
                    </ul>
                </nav>
            </section>  
    </header>
    :
    ''
    }
    </>   
    )
}

export default memo(Header)