import {saveTrafficParams, getThirdPartySource} from '../../helpers/functions';
saveTrafficParams('thirdParty');



let config={
APPID:'595048781716632',
FACEBOOK_PIXEL_ID : '628588785983328',//1540982699728838
FACEBOOK_PIXEL_SUBSCRIBE_EVENT_SUCCESS : 'Subscribe New',
FACEBOOK_PIXEL_SUBSCRIBE_EVENT_FAILED : 'SignUp',
FACEBOOK_PIXEL_SUBSCRIBE_EVENT_UNDER_PROCESSING : 'SignUp',
FACEBOOK_PIXEL_LEAD_EVENT:'Lead',
FACEBOOK_PIXEL_INITIAL_CHECKOUT_EVENT:'initial checkout'

}
// console.log('facebbok config js '+getThirdPartySource() );
if(getThirdPartySource() == 'dm'){
    // console.log('facebbok third party updated');
    config={
        APPID:'595048781716632',
        FACEBOOK_PIXEL_ID : '235946062821522',//1540982699728838
        FACEBOOK_PIXEL_SUBSCRIBE_EVENT_SUCCESS : 'PurchaseDM',
        FACEBOOK_PIXEL_SUBSCRIBE_EVENT_FAILED : 'SignUp',
        FACEBOOK_PIXEL_SUBSCRIBE_EVENT_UNDER_PROCESSING : 'CompleteRegistrationDM',
        FACEBOOK_PIXEL_LEAD_EVENT:'PurchaseDM',
        FACEBOOK_PIXEL_INITIAL_CHECKOUT_EVENT:'initial checkout',
    }
}

export default config