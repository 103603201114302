import { createContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from 'universal-cookie';
import axios from 'axios';
import {EncryptData, DecryptData, ConvertUrlToObject} from '../helpers/functions.js'
import config from '../Components/config';
import {getDeviceInfo} from '../helpers/deviceInfo';
var randomString = require('random-string');


export const AuthContext = createContext(null);
const cookies = new Cookies();
const Auth =  (props) => {

  const navigate = useNavigate();
    let isLoggedCookie = cookies.get('isLogged') ? cookies.get('isLogged') : false;    
    const [isLogged, setLogged] = useState(isLoggedCookie);    



    const location = useLocation();
    useEffect(()=>{
        if(!cookies.get('isLogged')){
            setLogged(false);
        }
        checkIfLogoutFromAllDevices();
    },[location]);

    const getTracker = () => {
        
        //const milliseconds = Math.floor(Date.now() / 1000);
        const trackerGeneration=()=>{
        return randomString({
                length: 12,
                numeric: true,
                letters: true,
                special: false
            });
        } 
    
    
        let trackerGenerationValue='';
        if(localStorage.getItem('tracker')==null)
        {
            trackerGenerationValue=trackerGeneration();
            localStorage.setItem("tracker", trackerGenerationValue);
        }
        else
        {
            trackerGenerationValue=localStorage.getItem('tracker')
            if(trackerGenerationValue==='QUxNJhnBGXuI')
            {
            // alert('hello man')
            }
        }
    
        return trackerGenerationValue;
    }

    const logTracking = (action='') => {

        if(!action) return;

        let trackerGenerationValue = getTracker();
        const request = axios.create({
            baseURL: config.serverDomain+'api',
            timeout: 20000,
            //transformRequest: [(data) => JSON.stringify(data.data)],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });         
        const dataTosend = {action,tracker:trackerGenerationValue};
        const dataEnc = EncryptData(JSON.stringify(dataTosend));    
        request.post('log_tracking', {"dataEnc":dataEnc});        
    }

    const getSelectedLanguage = () => {
        if(localStorage.getItem('lang')){
          return localStorage.getItem('lang');
        }
      
        return config.defualtLang;
      }    

    /**
     * login function
     */
    const login = async(user_email, password) => {

        let language = getSelectedLanguage();
        let trackerGenerationValue = getTracker();
        const request = axios.create({
            baseURL: config.loginApi,
            timeout: 20000,
            //transformRequest: [(data) => JSON.stringify(data.data)],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // or whatever you want, in key/value pair
            },
        });

        const dataTosend = {user_email, password, tracker:trackerGenerationValue, device_info:await getDeviceInfo(), language}
        const dataEnc = EncryptData(JSON.stringify(dataTosend));
        //console.log(dataEnc)

        return await request.post('',{"dataEnc":dataEnc}).then((res)=>{
            res.data = DecryptData(res.data,true);
            if(res.data.status == 1){ // success login
                let redirect = res.data.payload?.redirect || 'courses';
                successLoginProcess(res,redirect);                
                // cookies.set('isLogged', true, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                // cookies.set('user_id', res.data.payload.user_id, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                // cookies.set('access_token', res.data.payload.access_token, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                // cookies.set('refresh_token', res.data.payload.refresh_token, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                // cookies.set('acc_type', res.data.payload.acc_type, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                // cookies.set('user_name', res.data.payload.name, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                // setLogged(true);
                // window.$('#login').modal('hide');
                // let redirectUrl = config.domain+'dashboard'; 
                // if(localStorage.getItem('redAfterLogin')){
                //     redirectUrl = config.domain+localStorage.getItem('redAfterLogin');
                //     localStorage.removeItem('redAfterLogin'); 
                // }
                // window.location.href = redirectUrl; 
            }
            else{
                if(res.data.error==1){
                    let response = {status:res.data.status,id:0}
                    if(res?.data?.payload){
                        response = {...response,...res.data.payload};
                    }
                    return response;
                }
                setLogged(false);
            }
        }); 

    }

    /**
     * forget password request
     */
     const ForgetPasswrod = async(user_email) => {

        let trackerGenerationValue = getTracker();
        const request = axios.create({
            baseURL: config.serverDomain+'api/',
            timeout: 20000,
            //transformRequest: [(data) => JSON.stringify(data.data)],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                
                // or whatever you want, in key/value pair
            },
        });

        const lang = getSelectedLanguage();
        const dataTosend = {user_email, tracker:trackerGenerationValue, language:lang}
        const dataEnc = EncryptData(JSON.stringify(dataTosend));
        //console.log(dataEnc)

        return await request.post('sendForgetPassword',{"dataEnc":dataEnc}).then((res)=>{
            res.data = DecryptData(res.data,true);
            if(res.data.status >= 1){ // success forget request
                return res.data.status;
            }
            else{
                if(res.data.error==1){
                    return 0;
                }
            }
        }); 

    }    


    /**
     * register function
     */
     const register = async (values, isInvitation=false,social=false) => {

        let status = 0;
        const request = axios.create({
            baseURL: config.serverDomain+'api/',
            timeout: 20000,
            //transformRequest: [(data) => JSON.stringify(data.data)],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // or whatever you want, in key/value pair
            },
        });

        values['language'] = getSelectedLanguage();
        values['device_info'] = await getDeviceInfo();
        values['extra_info'] =  getTempExtraInfo();
        const dataEnc = EncryptData(JSON.stringify(values));
        await request.post('register',{"dataEnc":dataEnc}).then((res)=>{
            res.data = DecryptData(res.data, true);
            if(res.data.status == 1){ // success register
                cookies.set('isLogged', true, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('user_id', res.data.payload.user_id, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('access_token', res.data.payload.access_token, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('refresh_token', res.data.payload.refresh_token, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('acc_type', res.data.payload.acc_type, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('user_name', res.data.payload.name, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                setLogged(true);  
                setLoginExtraParam(res.data.payload.refresh_expire_after, res.data.payload.user_id, res.data.payload.device_id);
                status = res.data.status;

                let redirectUrl = '/prices'; 
                if(localStorage.getItem('redAfterLogin')){
                    redirectUrl = localStorage.getItem('redAfterLogin');
                    localStorage.removeItem('redAfterLogin'); 
                }   
                      
                redirectUrl += '?reg_popup=1'       
                if(isInvitation){
                    redirectUrl = '/courses';//'/prices'
                }
                if(res.data.payload.is_journey == 1){
                    redirectUrl = '/dashboard'; 
                }
                if(social){
                    redirectUrl = '/prices'; 
                }

                if(redirectUrl == '/courses'){
                    redirectUrl = '/signUpSuccess';
                    if(cookies.get('thirdPartySrcKey')){
                        redirectUrl = '/signUpSuccessDM';
                    }                    
                }

                if(redirectUrl == '/dashboard'){
                    if(cookies.get('thirdPartySrcKey')){
                        redirectUrl = '/successDashboardDm';
                    }
                }    
                
                if(redirectUrl == '/prices'){
                    if(cookies.get('thirdPartySrcKey')){
                        redirectUrl = '/SuccessPricesDm';
                    }
                }                   
                //navigate(redirectUrl);            
                window.location.href = localStorage.getItem('basename') + redirectUrl;
            }
            else{
                status = res.data.status;
                // 2 => parent email not exist
                // 3 => email used before
                setLogged(false);
            }
        }); 

        return status;

  }    


    /**
     * campaign registeration completion
     */
     const saveCampaignRegisteration = async (userId, token, values, redirectTo='') => {

        let status = 0;
        const request = axios.create({
            baseURL: config.serverDomain+'api/',
            timeout: 20000,
            //transformRequest: [(data) => JSON.stringify(data.data)],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // or whatever you want, in key/value pair
            },
        });

        values['language'] = getSelectedLanguage();
        values['token'] = token;
        values['userId'] = userId;
        values['device_info'] = await getDeviceInfo();
        const dataEnc = EncryptData(JSON.stringify(values));
        await request.post('saveCampaignRegisteration',{"dataEnc":dataEnc}).then((res)=>{
            res.data = DecryptData(res.data, true);
            if(res.data.status == 1){ // success register
                cookies.set('isLogged', true, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('user_id', res.data.payload.user_id, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('access_token', res.data.payload.access_token, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('refresh_token', res.data.payload.refresh_token, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('acc_type', res.data.payload.acc_type, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('user_name', res.data.payload.name, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                setLogged(true);  
                setLoginExtraParam(res.data.payload.refresh_expire_after, res.data.payload.user_id, res.data.payload.device_id);
                status = res.data.status;

                let redirectUrl = '/dashboard'; 
                if(localStorage.getItem('redAfterLogin')){
                    redirectUrl = localStorage.getItem('redAfterLogin');
                    localStorage.removeItem('redAfterLogin'); 
                }   
                      
                //navigate(redirectUrl);   
                if(redirectTo !=''){
                    redirectUrl = redirectTo;
                }         
                window.location.href = localStorage.getItem('basename') + redirectUrl;
            }
            else{
                status = res.data.status;
                // 2 => parent email not exist
                // 3 => email used before
                setLogged(false);
            }
        }); 

        return status;

  }    


    /**
     * campaign registeration completion
     */
     const saveUserRegistration = async (userId, token, values, redirectTo='') => {

        let status = 0;
        const request = axios.create({
            baseURL: config.serverDomain+'api/',
            timeout: 20000,
            //transformRequest: [(data) => JSON.stringify(data.data)],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // or whatever you want, in key/value pair
            },
        });

        values['language'] = getSelectedLanguage();
        values['token'] = token;
        values['userId'] = userId;
        values['device_info'] = await getDeviceInfo();
        const dataEnc = EncryptData(JSON.stringify(values));
        await request.post('saveUserRegistration',{"dataEnc":dataEnc}).then((res)=>{
            res.data = DecryptData(res.data, true);
            if(res.data.status == 1){ // success register
                cookies.set('isLogged', true, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('user_id', res.data.payload.user_id, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('access_token', res.data.payload.access_token, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('refresh_token', res.data.payload.refresh_token, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('acc_type', res.data.payload.acc_type, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('user_name', res.data.payload.name, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                setLogged(true);  
                setLoginExtraParam(res.data.payload.refresh_expire_after, res.data.payload.user_id, res.data.payload.device_id);
                status = res.data.status;

                let redirectUrl = '/dashboard'; 
                if(localStorage.getItem('redAfterLogin')){
                    redirectUrl = localStorage.getItem('redAfterLogin');
                    localStorage.removeItem('redAfterLogin'); 
                }   
                      
                //navigate(redirectUrl);   
                if(redirectTo !=''){
                    redirectUrl = redirectTo;
                }  
                
                if(redirectUrl == '/courses'){
                    redirectUrl = '/signUpSuccess';
                    if(cookies.get('thirdPartySrcKey')){
                        redirectUrl = '/signUpSuccessDM';
                    }
                }

                if(redirectUrl == '/dashboard'){
                    if(cookies.get('thirdPartySrcKey')){
                        redirectUrl = '/successDashboardDm';
                    }
                }    
                
                if(redirectUrl == '/prices'){
                    if(cookies.get('thirdPartySrcKey')){
                        redirectUrl = '/SuccessPricesDm';
                    }
                }                 

                window.location.href = localStorage.getItem('basename') + redirectUrl;
            }
            else{
                status = res.data.status;
                // 2 => parent email not exist
                // 3 => email used before
                setLogged(false);
            }
        }); 

        return status;

  }  

    /**
     * register msisdn function
     */
     const registerMsisdn = async (values) => {

        let status = 0;
        const request = axios.create({
            baseURL: config.serverDomain+'api/',
            timeout: 20000,
            //transformRequest: [(data) => JSON.stringify(data.data)],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // or whatever you want, in key/value pair
            },
        });

        values['language'] = getSelectedLanguage();
        values['device_info'] = await getDeviceInfo();
        const dataEnc = EncryptData(JSON.stringify(values));
        await request.post('registerMsisdn',{"dataEnc":dataEnc}).then((res)=>{
            res.data = DecryptData(res.data, true);
            if(res.data.status == 1){ // success register
                cookies.set('isLogged', true, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('user_id', res.data.payload.user_id, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('access_token', res.data.payload.access_token, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('refresh_token', res.data.payload.refresh_token, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('acc_type', res.data.payload.acc_type, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                cookies.set('user_name', res.data.payload.name, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
                setLogged(true);  
                setLoginExtraParam(res.data.payload.refresh_expire_after, res.data.payload.user_id, res.data.payload.device_id);
                status = res.data.status;

                let redirectUrl = '/'; 
                if(localStorage.getItem('redAfterLogin')){
                    redirectUrl = localStorage.getItem('redAfterLogin');
                    localStorage.removeItem('redAfterLogin'); 
                }    
                redirectUrl = '/profile?isEdit=1&focus=password'            
                navigate(redirectUrl);            
            }
            else{
                status = res.data.status;
                // 2 => parent email not exist
                // 3 => email used before
                setLogged(false);
            }
        }); 

        return status;

  }    

    /**
     * register function
     */
     const registerChild = async (values) => {

        let status = 0;
        const request = axios.create({
            baseURL: config.serverDomain+'api/',
            timeout: 20000,
            //transformRequest: [(data) => JSON.stringify(data.data)],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',            
            },
        });

        let response = null;
        values['language'] = getSelectedLanguage();
        values['extra_info'] = getTempExtraInfo();
        await authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('registerChild',values).then((res)=>{            
            if(res.data.status == 1){ // success register
                response = res.data;
          
            }
            else{
                response = res.data;
                // 3 => email used before
            }
        }); 

        return response;

  }  

    /**
     * register campaign journey
     */
     const registerCampaignJourney = async (values, campSrc = '') => {

        let status = 0;
        const request = axios.create({
            baseURL: config.serverDomain+'api/',
            timeout: 20000,
            //transformRequest: [(data) => JSON.stringify(data.data)],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',            
            },
        });


        
        const searchParams = new URLSearchParams(document.location.search);
        let campaignType = searchParams.get('campType');
        if(!campaignType || campaignType == undefined || campaignType==0 || campaignType==1){
          campaignType = 1
        }

        if(campSrc){
            campaignType = campSrc; // for example for theme campaign
        }
                
        let payload ={};
        let token = null;
        let clientSecret = null;
        let userId = 0;
        let response = null;
        values['language'] = getSelectedLanguage();
        values['campaign_type'] = campaignType;
        values['extra_info'] = getTempExtraInfo();
        await authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('registerCampaignJourney',values).then((res)=>{            
            payload = res?.data?.payload || {};
            if(res.data.status == 1){ // success register
                response = res.data;
                token = res.data.payload.token;
                clientSecret = res.data.payload.client_secret;
                userId = res.data.payload.user_id
          
            }
            else{
                response = res.data;
                // 3 => email used before
            }
            status = res.data.status
        }); 

        return {status, token, clientSecret, userId, data:{payload}};

  }

    /**
     * register email new flow
     */
     const registerEmailNewFlow = async (values) => {

        let status = 0;
        const request = axios.create({
            baseURL: config.serverDomain+'api/',
            timeout: 20000,
            //transformRequest: [(data) => JSON.stringify(data.data)],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',            
            },
        });

                
        let token = null;
        let clientSecret = null;
        let userId = 0;
        let response = null;
        values['language'] = getSelectedLanguage();
        values['extra_info'] = getTempExtraInfo();
        await authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('registerEmailNewFlow',values).then((res)=>{            
            if(res.data.status == 1){ // success register
                response = res.data;
                token = res.data.payload.token;
                userId = res.data.payload.user_id
          
            }
            else{
                response = res.data;
                // 3 => email used before
            }
            status = res.data.status
        }); 

        return {status, token, userId};

  }  
  

    /**
     * update temp user campaign journey
     */
     const updateTempCampaignJourney = async (email,token,userId, surveyToken='') => {
        let status = 0;
        let completionLink = '';
        const request = axios.create({
            baseURL: config.serverDomain+'api/',
            timeout: 20000,
            //transformRequest: [(data) => JSON.stringify(data.data)],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',            
            },
        });


        let response = null;
        let values = {email};
        values['language'] = getSelectedLanguage();
        await authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('updateTempCampaignJourney',{...values, token, userId, surveyToken}).then((res)=>{            
            if(res.data.status == 1){ // success register
                response = res.data;
          
            }
            else{
                response = res.data;
                // 3 => email used before
            }
            status = res.data.status
            completionLink = res.data?.payload?.completionLink || '';
        }); 

        return {status, completionLink};

  }
    /**
     * register campaign journey mobile
     */
     const registerCampaignJourneyMobile = async (values) => {

        let status = 0;
        const request = axios.create({
            baseURL: config.serverDomain+'api/',
            timeout: 20000,
            //transformRequest: [(data) => JSON.stringify(data.data)],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',            
            },
        });


        const searchParams = new URLSearchParams(document.location.search);
        let campaignType = searchParams.get('campType');
        if(!campaignType || campaignType == undefined || campaignType==0 || campaignType==1){
          campaignType = 1
        }

        let token = null;
        let clientSecret = null;
        let userId = 0;
        let response = null;
        values['language'] = getSelectedLanguage();
        values['campaign_type'] = campaignType;
        values['extra_info'] = getTempExtraInfo();
        await authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('registerCampaignJourneyMobile',values).then((res)=>{            
            if(res.data.status == 1){ // success register
                response = res.data;
                token = res.data.payload.token;
                clientSecret = res.data.payload.client_secret;
                userId = res.data.payload.user_id
          
            }
            else{
                response = res.data;
                // 3 => email used before
            }
            status = res.data.status
        }); 

        return {status, token, clientSecret, userId};

  }
  
    /**
     * register campaign journey mobile
     */
     const registerMobileNewFlow = async (values) => {

        let status = 0;
        const request = axios.create({
            baseURL: config.serverDomain+'api/',
            timeout: 20000,
            //transformRequest: [(data) => JSON.stringify(data.data)],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',            
            },
        });

        let token = null;
        let userId = 0;
        let response = null;
        values['language'] = getSelectedLanguage();
        values['extra_info'] = getTempExtraInfo();
        await authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('registerMobileNewFlow',values).then((res)=>{            
            if(res.data.status == 1){ // success register
                response = res.data;
                token = res.data.payload.token;
                userId = res.data.payload.user_id
          
            }
            else{
                response = res.data;
                // 3 => email used before
            }
            status = res.data.status
        }); 

        return {status, token, userId};

  }

  const saveJoinOurTeam = async(values) => {

    let response = null;
    values['language'] = getSelectedLanguage();
    await authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded',}).post('joinOurTeam',values).then((res)=>{            
        response = res.data.status;
    }); 
    return response;

  }
  
    /**
     * 
     * get refresh token
     */
    const getRefreshToken = () => {
        if(isLoggedCookie){
            return cookies.get('refresh_token');
        }
        return null;
    }

    /**
     * 
     * get access token
     */
    const getAccessToken = () => {
        if(isLoggedCookie){
            return cookies.get('access_token');
        }
        return null;
    }  

    /**
     * 
     * get user id
     */
     const getUserId = () => {
        if(isLoggedCookie){
            return cookies.get('user_id');
        }
        return null;
    }   
    

    const getAccountType =  () => {
        if(isLoggedCookie){
            return cookies.get('acc_type');
        }
        return 0;
        
    }  
    
    
    const getUserName =  (fnameOnly=true) => {
        if(isLoggedCookie){
            let fullName = cookies.get('user_name');
            if(fnameOnly){
                let name =  fullName.split(" ")[0];
                if(name == 'notSet'){
                    name = '';
                }
                return name;
            }

            if(fullName.includes('notSet')){
                fullName = '';
            }
            return fullName;
        }
        return '';
        
    }     
    
    const getUserEmail = () => {
        if(isLogged){
            return authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('getUserEmail',{})
        }
        return null;
    }    
    
    /**
     * 
     * update access token
     */    
    const updateAccessToken = (token) => {
        if(token){
            cookies.set('access_token', token, { path: '/'});
        }
        else{
            
        }
    }

    /**
     * 
     * requests for logged/authorized user
     * any api request need authorization should bee use this function
     */    
    const authorizedRequest = (headers='',options={}, requestPrefix='api',decrypt=true) => {

        /**
         * inital config
         */
        let axiosObj = {
            baseURL: config.serverDomain+requestPrefix,
            headers: {
              "Content-Type": "application/json",
            },
            ...options
          };
        if(headers){
            axiosObj.headers = headers;
        }
        const instance = axios.create(axiosObj);



          /**
           * set middleware function before send request
           */
          instance.interceptors.request.use(
            (config) => {

                
                if(!config._retry && !["uploadProfileImage","saveNewProjectFile"].includes(config.url)){ // no need to do enc again after expired token


                        if(!config.params){
                            config.params = {};
                        }
                        if(!config.data){
                            config.data = {};
                        }       
                        let urlObj = ConvertUrlToObject(config.url);

                        config.url = urlObj.domain;                
                        config.params = {...config.params,...config.data, ...urlObj.payloadObj}
                        
                        config.data = null;
                        let dataEnc = '';
                                        
                        if(config.params){
                            let dataString = JSON.stringify(config.params);
                            dataEnc = EncryptData(dataString);
                        }
                        config.params = null;
                        config.data = {"dataEnc":dataEnc}

                }
                
              const token = getAccessToken();
              const user = getUserId();
              if (token && user) {
                // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
                config.headers["x-access-token"] = token; 
                config.headers["x-user-id"] = user; 
                config.headers["Content-Type"] = axiosObj.headers['Content-Type'];
                //console.log(token);
              }
              return config;
            },
            (error) => {
              return Promise.reject(error);
            }
          );  
          
          



          /**
           * middleware function for response
           * if expired token, should call refresh token function to get new access token 
           * then call the original requset again with new access token 
           */
          instance.interceptors.response.use(
            async (res) => {              

                /*
                if(headers?.withoutDecryption == 1){


                    const blb    = new Blob([res.data], {type: "text/plain"});
                   // const reader = new FileReader();  
                    await blb?.text()?.then((result)=> {
                        //console.log(result);
                        try{
                            let  tempDecrypt = DecryptData(result,true);
                            if(tempDecrypt?.status === 600){
                                console.log('all');
                                res.data = tempDecrypt;
                            }                            
                        }
                        catch{
                            
                        }

                        //console.log(tempDecrypt);
                    });                  
                }
                else{
                    */
                    if(decrypt){
                        res.data = DecryptData(res.data,true);
                    }
               // }
                //console.log(res.data)
              const originalConfig = res.config;                         
                // Access Token was expired  
                //console.log(res.data )            
                if (res.data.status === 600 && !originalConfig._retry) {
                  originalConfig._retry = true;
          
                  try {
                    const rs = await instance.post("/refresh", {
                      refresh_token: getRefreshToken(),
                      user_id : getUserId()
                    },
                    {            
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    });                    
                    if(rs.data.status==700){
                        await storageLogout();
                        window.location.href = localStorage.getItem('basename')+'/';
                        return ; 
                    }
                    const { access_token } = rs.data.payload;
                    //console.log(access_token);
                    updateAccessToken(access_token);
                    //console.log(originalConfig);
                    return instance(originalConfig);
                  } catch (_error) {
                    return Promise.reject(_error);
                  }
                }
                //failed authentication
                else if(res.data.status === 500 && !originalConfig._retry){
                   // storageLogout();
                   setLogged(false);
                }
                else if(originalConfig._retry){ //means second try 
                    //to prevent ambigus issue (after expired refresh token)
                    //storageLogout();
                }

                return res;




            },
            async (err) => {        
              return Promise.reject(err);
            }
          )


          return instance;


    }


    const logout = async () => {

        const request = axios.create({
            baseURL: config.serverDomain+"api",
            timeout: 20000,
            //transformRequest: [(data) => JSON.stringify(data.data)],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                
                // or whatever you want, in key/value pair
            },
        });

        let access_token = getAccessToken();
        let refresh_token = getRefreshToken();
        let user_id = getUserId();
        const dataTosend = {access_token,refresh_token,user_id};
        const dataEnc = EncryptData(JSON.stringify(dataTosend));
        request.post('/logout',{"dataEnc":dataEnc}).then(async (res)=>{
            res.data = DecryptData(res.data, true)
            //if(res.data.status == 1){ // success login
              await storageLogout();
              window.location.href = localStorage.getItem('basename')+'/';//config.domain;

            //}
        }); 

    }

    const setLoginExtraParam = (expiryDate,userId,deviceId) => {
        //console.log(expiryDate + 31536000);
        cookies.set('logout_action', true, { path: '/' , maxAge: expiryDate+ 31536000}); // plus one year
        cookies.set('logout_user_id', userId, { path: '/' , maxAge: expiryDate+ 31536000}); // plus one year
        cookies.set('device_id', deviceId, { path: '/' , maxAge: expiryDate+ 31536000}); // plus one year
    }

    const storageLogout =async(dontRedirect) => {
        cookies.set('isLogged', false, { path: '/' , maxAge: -1});
        cookies.set('user_id', '', { path: '/' , maxAge: -1});
        cookies.set('access_token', '', { path: '/' , maxAge: -1});
        cookies.set('refresh_token', '', { path: '/' , maxAge: -1});
        cookies.set('acc_type', '', { path: '/' , maxAge: -1});
        cookies.set('user_name', '', { path: '/' , maxAge: -1});

        const logoutAction = cookies.get('logout_action');
        const logoutUserId = cookies.get('logout_user_id');
        const deviceId = cookies.get('device_id');
        //console.log('---',logoutAction,'---');
        if(logoutAction){
            //console.log('---','logout ','---');
            let req = await authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('setLoggedOut',{userId:logoutUserId,deviceId});
            let status = req?.data?.status || 0;
            if(status ==1 ){
                cookies.set('logout_action', false, { path: '/' , maxAge: -1});
                cookies.set('device_id', '', { path: '/' , maxAge: -1});                
            }
        }
        if(dontRedirect == false){
            window.location.href = localStorage.getItem('basename') || '/' ;//config.domain;
        }
        //setLogged(false);
        

    }

    const isActiveSubscriber = async(alsoActiveUser=false) => {
        let req = await authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('is_active_subscriber');
        let status = req?.data?.status || 0;
        let isActiveUser = req?.data?.payload?.isActiveUser || 0;
        //console.log(status);
        if(alsoActiveUser){
            return [status, isActiveUser];
        }
        return status
    }

    const sendActivationEmail = () => {
        if(isLogged){
            const language = getSelectedLanguage();
            return authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('sendActivationEmail',{language}).then ( res => {
                if(res.data?.status ==1){
                    return true;
                }
                return false;
            }).catch(err => {
                return false;
            })
        }
        else{
            return false
        }
    }

    const sendCompleteRegistrationEmail = (token) => {
        if(token){
            //console.log(token)
            const language = getSelectedLanguage();
            return authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('sendCompleteRegistrationEmail',{token,language}).then ( res => {
                if(res.data?.status ==1){
                    return true;
                }
                return false;
            }).catch(err => {
                return false;
            })
        }
        else{
            return new Promise(()=>true,()=>false);
        }
    }    

    const sendCompleteRegistrationEmailUserRegistration = (token) => {
        if(token){
            //console.log(token)
            const language = getSelectedLanguage();
            return authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('sendCompleteRegistrationEmailUserRegistration',{token,language}).then ( res => {
                if(res.data?.status ==1){
                    return true;
                }
                return false;
            }).catch(err => {
                return false;
            })
        }
        else{
            return new Promise(()=>true,()=>false);
        }
    }      
    
    const childActivationJourney = () => {
        if(isLogged){
            const language = getSelectedLanguage();
            return authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('ChildActivationJourney',{language}).then ( res => {
                if(res.data?.status ==1){
                    return true;
                }
                return false;
            }).catch(err => {
                return false;
            })
        }
        else{
            return false
        }
    }    


    const isActiveAccount = async() => {
        let req = await authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('is_active_account');
        let status = req?.data?.status || 0;
        if(status == 1){
            return true;
        }
        return false;
    }    

    const successLoginProcess = (res, redirectTo='', dontRedirect=false) => {

        cookies.set('isLogged', true, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
        cookies.set('user_id', res.data.payload.user_id, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
        cookies.set('access_token', res.data.payload.access_token, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
        cookies.set('refresh_token', res.data.payload.refresh_token, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
        cookies.set('acc_type', res.data.payload.acc_type, { path: '/' , maxAge: res.data.payload.refresh_expire_after});
        cookies.set('user_name', res.data.payload.name, { path: '/' , maxAge: res.data.payload.refresh_expire_after});

        if(!dontRedirect){
            setLogged(true);
            setLoginExtraParam(res.data.payload.refresh_expire_after, res.data.payload.user_id, res.data.payload.device_id);
        }
        window.$('#login').modal('hide');
        let redirectUrl = localStorage.getItem('basename') + '/dashboard';//config.domain+'dashboard'; 
        if(localStorage.getItem('redAfterLogin')){
            redirectUrl = localStorage.getItem('basename') + '/'+localStorage.getItem('redAfterLogin');//config.domain+localStorage.getItem('redAfterLogin');
            localStorage.removeItem('redAfterLogin'); 
        }
        if(redirectTo){
            redirectUrl = localStorage.getItem('basename') + '/'+redirectTo;//config.domain+redirectTo;
        }
        if(localStorage.getItem('forceRedAfterLogin')){
            redirectUrl = localStorage.getItem('basename') + '/'+localStorage.getItem('forceRedAfterLogin');//config.domain+localStorage.getItem('forceRedAfterLogin');
            localStorage.removeItem('forceRedAfterLogin'); 
        }        

        if(dontRedirect == true){
            return;
        }
        else{
            window.location.href = redirectUrl; 
        }


    }

    const getCampaignRegData = async(token,userId,isAsync=false) => {
        if(!isAsync){
            let req = await authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('getCampaignData',{token,userId});
            return req;
        }
        else{
            let req = authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('getCampaignData',{token,userId});
            return req;
        }
    }

    const getUserRegistrationData = async(token,userId,isAsync=false) => {
        if(!isAsync){
            let req = await authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('getUserRegistrationData',{token,userId});
            return req;
        }
        else{
            let req = authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('getUserRegistrationData',{token,userId});
            return req;
        }
    }    


    const logoutFromAllDevices = async(id, password) => {
        await authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('logoutFromAllDevices',{id, password}).then(res => {
            if(res.data.status ==1){
                return true;
            }
            return false;
        }).catch(error => {
            return false
        })
    }

    const checkIfLogoutFromAllDevices = () => {        
        if(isLogged){            
            let interval1 = setInterval(() => {
                if(!isLogged){
                    clearInterval(interval1);
                    return;
                }
                authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('isLogoutFromAll',{}).then(res => {
                    if(res.data.status ==1){
                        logout();
                        clearInterval(interval1);
                        window.location.href = localStorage.getItem('basename') + '/';
                    }
                    else{
                        if(res.data?.payload?.acc_type){
                            let tempAcc =  cookies.get('acc_type');
                            if(tempAcc != res.data.payload.acc_type){
                                cookies.set('acc_type', res.data.payload.acc_type, { path: '/' , maxAge: 31536000});
                            }
                        }
                    }
                }).catch(error => {
    
                })

            }, 600000);

        }
    }

    const getCountryPrefix = () => {
        return authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded'}).post('getCountryPrefix',{});
    }

    const getTempExtraInfo = () => {
        const trafficSrcVal = cookies.get('trafficSrcVal');
        const trafficSrcKey = cookies.get('trafficSrcKey');
        const thirdPartySrcVal = cookies.get('thirdPartySrcVal');
        const thirdPartySrcKey = cookies.get('thirdPartySrcKey');        
        return {trafficSrcVal, trafficSrcKey, thirdPartySrcVal, thirdPartySrcKey};
    }

    return (
        <AuthContext.Provider value={{isLogged, login, authorizedRequest, logout, register, isActiveSubscriber, getTracker, getUserId,logTracking,getAccountType,registerChild,getUserName,ForgetPasswrod,isActiveAccount,successLoginProcess,sendActivationEmail,registerMsisdn, saveJoinOurTeam,childActivationJourney, registerCampaignJourney, getCampaignRegData, saveCampaignRegisteration, storageLogout,sendCompleteRegistrationEmail, logoutFromAllDevices, getUserEmail, registerCampaignJourneyMobile, updateTempCampaignJourney, registerEmailNewFlow, registerMobileNewFlow, sendCompleteRegistrationEmailUserRegistration, getUserRegistrationData, saveUserRegistration, getCountryPrefix,getTempExtraInfo}}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default Auth;