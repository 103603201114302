import { useContext, useEffect, useRef, useState } from 'react';
import './customStyle.css'
import { trim } from '../../../helpers/functions';
import OnOffToggle from '../onOffToggle';
import { UserContext as language } from '../../../context/languageContext';
import Hls from 'hls.js';

const CustomVideoPlayer = ({id='customePlayer1', src, poster='', onProgress=null, onFinish=null, subTitleFile='', subTitleLang='', showTranscript=false, type='application/x-mpegURL', ...rest}) => {
console.log(src)
    let Player = useRef(null);
    const [url, setUrl] = useState(src);//https://so2alak.com/index.php?r=secFetch/PlayVideo&vod=18740
    const [captionFile, setCaptionFile] = useState(subTitleFile);
    let [subTitleContent, setSubTitleContet] = useState([]);
    let [captionFileLang, setCaptionFileLang] = useState(subTitleLang);
    let [captionDirection, setCaptionDirection] = useState('ltr'); 

    const Lang = useContext(language);

    useEffect(()=>{
        if(Player){                    

            const playerWrapper = document.querySelector("#"+id);

            const container = playerWrapper.querySelector(".video-container");
            const mainVideo = container.querySelector("video");
            const videoTimeline = container.querySelector(".video-timeline");
            const progressBar = container.querySelector(".progress-bar");
            const volumeBtn = container.querySelector(".volume i");
            const volumeSlider = container.querySelector(".left input");
            const currentVidTime = container.querySelector(".current-time");
            const videoDuration = container.querySelector(".video-duration");
            const skipBackward = container.querySelector(".skip-backward i");
            const skipForward = container.querySelector(".skip-forward i");
            const playPauseBtn = container.querySelector(".play-pause i");
            const speedBtn = container.querySelector(".playback-speed span");
            const speedOptions = container.querySelector(".speed-options");
            const pipBtn = container.querySelector(".pic-in-pic span");
            const fullScreenBtn = container.querySelector(".fullscreen i");
            const closeFullScreenBtn = container.querySelector(".closeFullscreen");
            let timer;


          if(mainVideo && progressBar){ // rest player
              mainVideo.pause();  
              mainVideo.currentTime = 0;
              mainVideo.load();
              progressBar.style.width = '0%';
          }


            if(src.includes("format=m3u8") || src.includes(".m3u8")){
                    if (Hls.isSupported() ) {                
                    var hls = new Hls();
                    hls.loadSource(src);
                    hls.attachMedia(mainVideo);
                    
                    }
                    // HLS.js is not supported on platforms that do not have Media Source
                    // Extensions (MSE) enabled.
                    //
                    // When the browser has built-in HLS support (check using `canPlayType`),
                    // we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video
                    // element through the `src` property. This is using the built-in support
                    // of the plain video element, without using HLS.js.
                    else if( (mainVideo.canPlayType('application/vnd.apple.mpegurl') || mainVideo.canPlayType('audio/mpegurl'))){
                        //mainVideo.type="application/x-mpegURL";
                        mainVideo.stc = src;
                    }
            }
            

            const subtitleFile = ParseSubTitleFile(subTitleFile);   


            const hideControls = () => {
                if (mainVideo.paused) return;
                timer = setTimeout(() => {
                container.classList.remove("show-controls");
                }, 3000);
            };
            hideControls();
          
          container.addEventListener("mousemove", () => {
            container.classList.add("show-controls");
            clearTimeout(timer);
            hideControls();
          });
          
          const formatTime = (time) => {
            let seconds = Math.floor(time % 60),
            minutes = Math.floor(time / 60) % 60,
            hours = Math.floor(time / 3600);
            seconds = seconds < 10 ? `0${seconds}` : seconds;
            minutes = minutes < 10 ? `0${minutes}` : minutes;
            hours = hours < 10 ? `0${hours}` : hours;
            if (hours == 0) {
              return `${minutes}:${seconds}`;
            }
            return `${hours}:${minutes}:${seconds}`;
          };
          
          videoTimeline.addEventListener("mousemove", (e) => {
            let timelineWidth = videoTimeline.clientWidth;
            let offsetX = e.offsetX;
            let percent = Math.floor(
              (offsetX / timelineWidth) * mainVideo.duration
            );
            const progressTime = videoTimeline.querySelector("span");
            offsetX =
              offsetX < 20
                ? 20
                : offsetX > timelineWidth - 20
                ? timelineWidth - 20
                : offsetX;
            progressTime.style.left = `${offsetX}px`;
            progressTime.innerText = formatTime(percent);
          });
          
          videoTimeline.addEventListener("click", (e) => {
            let timelineWidth = videoTimeline.clientWidth;
            mainVideo.currentTime =
              (e.offsetX / timelineWidth) * mainVideo.duration;
          });
          
          mainVideo.addEventListener("timeupdate", (e) => {
            let { currentTime, duration } = e.target;
            let percent = (currentTime / duration) * 100;
            progressBar.style.width = `${percent}%`;
            currentVidTime.innerText = formatTime(currentTime);
          });


          mainVideo.addEventListener("ended", (e) => {
            //console.log('ended')
                if (onFinish != null) {
                    onFinish();
                }
          });          


          
          mainVideo.addEventListener("loadeddata", () => {
            videoDuration.innerText = formatTime(mainVideo.duration);
          });
          
          const draggableProgressBar = (e) => {
            let timelineWidth = videoTimeline.clientWidth;
            progressBar.style.width = `${e.offsetX}px`;
            mainVideo.currentTime =
              (e.offsetX / timelineWidth) * mainVideo.duration;
            currentVidTime.innerText = formatTime(mainVideo.currentTime);
          };
          
          volumeBtn.addEventListener("click", () => {
            if (!volumeBtn.classList.contains("fa-volume-high")) {
              mainVideo.volume = 0.5;
              volumeBtn.classList.replace("fa-volume-xmark", "fa-volume-high");
            } else {
              mainVideo.volume = 0.0;
              volumeBtn.classList.replace("fa-volume-high", "fa-volume-xmark");
            }
            volumeSlider.value = mainVideo.volume;
          });
          
          volumeSlider.addEventListener("input", (e) => {
            mainVideo.volume = e.target.value;
            if (e.target.value == 0) {
              return volumeBtn.classList.replace(
                "fa-volume-high",
                "fa-volume-xmark"
              );
            }
            volumeBtn.classList.replace("fa-volume-xmark", "fa-volume-high");
          });
          
          speedOptions.querySelectorAll("li").forEach((option) => {
            option.addEventListener("click", () => {
              mainVideo.playbackRate = option.dataset.speed;
              speedOptions.querySelector(".active").classList.remove("active");
              option.classList.add("active");
            });
          });
          
          document.addEventListener("click", (e) => {
            if (
              e.target.tagName !== "SPAN" ||
              e.target.className !== "material-symbols-rounded"
            ) {
              speedOptions.classList.remove("show");
            }
          });
          
          


          container.onkeydown = function(evt) {
            evt = evt || window.event;
            var isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key === "Escape" || evt.key === "Esc");
            } else {
                isEscape = (evt.keyCode === 27);
            }
            if (isEscape) {
              container.classList.remove("fullscreen");
              fullScreenBtn.classList.replace("fa-compress", "fa-expand");
            }
          };          
          closeFullScreenBtn.addEventListener("click", () => {
            container.classList.remove("fullscreen");
            fullScreenBtn.classList.replace("fa-compress", "fa-expand");
          });          

          fullScreenBtn.addEventListener("click", () => {
            container.classList.toggle("fullscreen");
            var isInFullScreen = container.classList.contains('fullscreen');

            if (isInFullScreen) {
                fullScreenBtn.classList.replace("fa-expand", "fa-compress");                
                return ;
            }

            fullScreenBtn.classList.replace("fa-compress", "fa-expand");
          });
          
          speedBtn.addEventListener("click", () =>
            speedOptions.classList.toggle("show")
          );
          pipBtn.addEventListener("click", () =>
            mainVideo.requestPictureInPicture()
          );
          skipBackward.addEventListener(
            "click",
            () => (mainVideo.currentTime -= 5)
          );
          skipForward.addEventListener("click", () => (mainVideo.currentTime += 5));
          mainVideo.addEventListener("play", () =>{
            playPauseBtn.classList.replace("fa-play", "fa-pause")
            document.querySelector('.video-cover-image').style.display = 'none';
          }
          );
          mainVideo.addEventListener("pause", () =>
            playPauseBtn.classList.replace("fa-pause", "fa-play")
          );
          playPauseBtn.addEventListener("click", () =>
            mainVideo.paused ? mainVideo.play() : mainVideo.pause()
          );
          videoTimeline.addEventListener("mousedown", () =>
            videoTimeline.addEventListener("mousemove", draggableProgressBar)
          );
          document.addEventListener("mouseup", () =>
            videoTimeline.removeEventListener("mousemove", draggableProgressBar)
          );













            
        }

          return () => {
            Player = null;
          }
    
    },[Player,src])
    

    const ontimeupdate =(e) => {
        //console.log('updated')
        const currentTime = (e.target.currentTime);
        const duration = e.target.duration;

        if(onProgress != null){
            onProgress(currentTime,duration)
          }

        if(subTitleContent){
            updateSubTitleText(currentTime);
            updateTranscriptText(currentTime);
        }       
    } 
    

    
    

    const ParseSubTitleFile = async (fileUrl) => {
        if(fileUrl){
            const response =  await fetch(fileUrl);
            const data = await response.text();
            const fileItems = data.split(/\n\r?\n/);
            let newArr = [];        
            fileItems.forEach( function( value, index ) {
              let item = fileItems[index].split('\n'); 
              
    
              //let metaLang = item.find( meta => meta.toLowerCase().match(/^language[\s]*:[A-Za-z]+$/))
              let metaLang = item.find( meta => /language[\s]*:[\s]*[A-Za-z]+/.test(meta.toLowerCase()));          
              if(metaLang){
                  const camptionMeta = metaLang.split(':');
                  if(camptionMeta.length ==2 && camptionMeta[0].toLowerCase() == 'language' && camptionMeta[1]){          
                    let newLang = trim(camptionMeta[1]," ");
                    newLang = trim(newLang,"\r");
                    newLang = trim(newLang,"\n");
                    captionFileLang = newLang;
                    if(captionFileLang == 'ar'){
                      setCaptionDirection('rtl');
                    }
                    else{
                      setCaptionFileLang(newLang);
                    }
                    
                  }            
              }
              else{
                 if(captionFileLang == 'ar'){
                    setCaptionDirection('rtl');
                 }
              }
              
    
              
              if(isNaN(item[0])){
                item = [index, ...item]
                // item[2] = item[1] + item[2] ? item[2] : '';
                // item[1] = item[0];
                // item[0] = index;
    
              }       
              item[2] = item.filter((v,i) => i>=2).join('\r')
              //item[2] = item[2] && item[2].replace(/(\r\n|\n|\r)/gm, "<br/>"); 
              item[2] = trim(item[2]," ");
              item[1] = item[1] && item[1].replace(/(\r\n|\n|\r)/gm, ""); 
              //item[2] = item[2] && item[2].replace(/(\r\n|\n|\r)/gm, ". ");                                
              if(item[1] && item[2]){
                const fromTo = item[1].split('-->');
                //console.log(fromTo);
                if(fromTo[1] && item[2] && item[2] !='\r'){
                    newArr.push({
                      'order' : item[0],
                      'from' : fromTo[0],
                      'to' : fromTo[1],
                      'text' : item[2]
                    })    
                }
              }
        
              });    
              //console.log(newArr);
              setSubTitleContet(newArr);
              subTitleContent = newArr; 
              //console.log(subTitleContent);             
            //console.log(fileItems)
        }
      }    



      const updateSubTitleText = (time) => {
        //console.log(time);
        window.$('#'+id+' .custom-subtitle span').addClass('hide');
        window.$('#'+id+' .custom-subtitle span').text('');
        subTitleContent.forEach((val,index)=> {
          const fromArr = val.from.split(':'); // split it at the colons
          const toArr = val.to.split(':'); // split it at the colons
          const fromSeconds = (parseFloat(fromArr[0]) * 60 * 60) + (parseFloat(fromArr[1]) * 60) + parseFloat(fromArr[2]); 
          const toSeconds = (parseFloat(toArr[0]) * 60 * 60) + (parseFloat(toArr[1]) * 60) + parseFloat(toArr[2]); 
              
          if(time >= fromSeconds && time <= toSeconds){
            //console.log(fromSeconds+' -> '+toSeconds); 
            //console.log(val.text);
            window.$('#'+id+' .custom-subtitle span').removeClass('hide');
            window.$('#'+id+' .custom-subtitle span').text(val.text);
          }
          
        })
        //window.$('#'+id+' .custom-subtitle span').html('');
      }  
      
      
      const updateTranscriptText = (time) => {
        //console.log(time);
        time = parseFloat(time);
        window.$('.'+id+'-transcript-items').removeClass('highlighted');
        
        subTitleContent.forEach((val,index)=> {
          const fromArr = val.from.split(':'); // split it at the colons
          const toArr = val.to.split(':'); // split it at the colons
          const fromSeconds = (parseFloat(fromArr[0]) * 60 * 60) + (parseFloat(fromArr[1]) * 60) + parseFloat(fromArr[2]); 
          const toSeconds = (parseFloat(toArr[0]) * 60 * 60) + (parseFloat(toArr[1]) * 60) + parseFloat(toArr[2]);  
          //console.log(time+' : '+fromSeconds+' -> '+toSeconds )   
          if(time >= fromSeconds && time <= toSeconds){
            //console.log('.transcript-item-'+val.order);
            window.$('#'+id+'-transcript-item-'+val.order).addClass('highlighted');
            //console.log(window.$('#transcript-item-'+val.order).html())
          }
          
        })
        //window.$('#'+id+' .custom-subtitle span').html('');
      }       
    


    return (
        <>
            <div key={id+'-'+src} id={id} class="custom-player" {...rest} style={{maxWidth: '100%'}}>
                <div className="video-container show-controls" style={{width:'100%', height:"100%"}}>
                <div className="closeFullscreen"><i className="fa fa-times"></i></div>  
                <div className='video-overly'></div>  
                <div className="custom-video-wrapper">
                    <div className="video-timeline">
                    <div className="progress-area">
                        <span>00:00</span>
                        <div key={'bar-'+src} className="progress-bar"></div>
                    </div>
                    </div>
                    <ul className="video-controls">
                    <li className="video-options left">
                        <button className="volume">
                        <i className="fa-solid fa-volume-high"></i>
                        </button>
                        <input type="range" min="0" max="1" step="any" />
                        <div className="video-timer">
                        <p className="current-time">00:00</p>
                        <p className="separator">/</p>
                        <p className="video-duration">00:00</p>
                        </div>
                    </li>
                    <li className="video-options center">
                        <button className="skip-backward">
                        <i className="fa fa-backward"></i>
                        </button>
                        <button className="play-pause"><i className="fa fa-play"></i></button>
                        <button className="skip-forward"><i className="fa fa-forward"></i></button>
                    </li>
                    <li className="video-options right">
                        <div className="playback-content">
                        <button className="playback-speed">
                            <span className="material-symbols-rounded">slow_motion_video</span>
                        </button>
                        <ul className="speed-options">
                            <li data-speed="2">2x</li>
                            <li data-speed="1.5">1.5x</li>
                            <li data-speed="1" className="active">Normal</li>
                            <li data-speed="0.75">0.75x</li>
                            <li data-speed="0.5">0.5x</li>
                        </ul>
                        </div>
                        <button className="pic-in-pic hide">
                        <span className="material-icons">picture_in_picture_alt</span>
                        </button>
                        <button className="fullscreen">
                        <i className="fa fa-expand"></i>
                        </button>
                    </li>
                    </ul>
                </div>
                {poster!='' && <img src={poster} alt="Cover Image" class="video-cover-image"/>}
                <video key={src} onTimeUpdate={ontimeupdate} ref={Player} style={{width:'100%', height:"100%", 'objectFit':"contain"}}  playsInline webkit-playsInline>
                    <source src={src} type={type} preload="auto"></source>                
                </video>

                { subTitleFile &&
                  <div class="custom-subtitle hide" style={{direction:captionDirection,textAlign:"center",position: "absolute",bottom: "65px",width: "100%"}}>
                    <span style={{opacity: "0.6",background:"black",color:"white",fontSize: "1.7rem",padding: "3px"}}></span>
                  </div>
                }

                </div>  

            </div> 

        { subTitleFile ?
            <OnOffToggle lang={Lang.getSelectedLanguage()} onLabel={Lang.on} offLabel={Lang.off} label={Lang.captions}>
            {
                (isChcked, elements)=>{
                    if(isChcked) {
                        window.$('#'+id+' .custom-subtitle').removeClass('hide');
                    } 
                    else{
                        window.$('#'+id+' .custom-subtitle').addClass('hide');
                    }
                    return elements();
                }
            } 
            </OnOffToggle>
            :''
            }

            {subTitleFile && showTranscript==true ? <div className={id+'-transcript-area'} style={{direction:captionDirection}}>
                {
                subTitleContent.map(item => {
                    return <span class={id+"-transcript-items transcript-items"} id={id+`-transcript-item-${item.order}`}>{item.text}, </span>
                })
                }
            </div> : ''
            }

        </> 
        
        

    )
}

export default CustomVideoPlayer;