import React from 'react';
import {FileIcon, defaultStyles} from 'react-file-icon';
import uuid from 'react-uuid';
import { createObjectURL, base64StringToBlob } from 'blob-util';
import Cookies from 'universal-cookie';

export const formatDate = (date) =>{
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}


export const getAge = (dateString) =>{
    if(isNaN(parseFloat(dateString))){
        return 0;
    }
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const subtractYears = (date, years) => {
    date.setFullYear(date.getFullYear() - years);
    return date;
}

export const fileInfoFromPath = (path) => {    
    let filename = path.split('/').pop().split('#')[0].split('?')[0].split('&')[0];
    var fileType = {type:'', icon:'',filename:filename};
    const extList = ['mp4','m4v','mp3','csv','xls','xlsx','doc','docx','ppt','txt','pdf','vtt'];
    if(path){
        for(var i=0;i< extList.length; i++){
            if (path.includes('.'+extList[i])) {
                fileType.type = extList[i];
                fileType.icon = getFileIcon(extList[i]);
                break;
            }
        }
    }

    return fileType;
}


export const loadCaptionFiles=(filePath,captionLang)=>
{
    let filename = filePath.split('/');//.pop().split('#')[0];//.split('?')[0].split('&')[0];
    let captionPath='';
    if(filename)
    {

        for(var i=0;i<filename.length;i++)
        {
            if (filename[i].includes('?')) {
                let extraPath=filename[i].split('?')[1]
                captionPath+=captionLang+'.vtt'+'?'+extraPath;
            }
            else
                captionPath+=filename[i]+'/';
        }
    }
    return(captionPath);
}

export const getFileIcon = (extension,withContainer=true) =>{
    if(withContainer){
        return <div style={{width:'50px', margin:'0 auto'}}><FileIcon extension={extension}  {...defaultStyles[extension=='csv'?'xls':extension]} /></div>;
    }
    else{
        return <FileIcon extension={extension}  {...defaultStyles[extension=='csv'?'xls':extension]} />
    }            
}

const encryptMethod=()=> {
    return 'AES-256-CBC';
}
const encryptMethodLengthFunction=()=> {
    var encryptMethodString = encryptMethod();
    // get only number from string.
    var aesNumber = encryptMethodString.match(/\d+/)[0];
    return parseInt(aesNumber);
}

/** decrption function */
export const DecryptData = (encryptedBase64,doJsonParse=false, key='@BeeCell@') => {
    const CryptoJS = require('crypto-js');
    var json = JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(encryptedBase64)));
 
    var salt = CryptoJS.enc.Hex.parse(json.salt);
    var iv = CryptoJS.enc.Hex.parse(json.iv);

    var encrypted = json.ciphertext;// no need to base64 decode.

    var iterations = parseInt(json.iterations);
    if (iterations <= 0) {
        iterations = 1;
    }
    var encryptMethodLength = (encryptMethodLengthFunction()/4);// example: AES number is 256 / 4 = 64
    var hashKey = CryptoJS.PBKDF2(key, salt, {'hasher': CryptoJS.algo.SHA512, 'keySize': (encryptMethodLength/8), 'iterations': iterations});

    var decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {'mode': CryptoJS.mode.CBC, 'iv': iv});

    if(doJsonParse){
        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));     
    }
    return decrypted.toString(CryptoJS.enc.Utf8); 
}

/** encryption function */
export  const EncryptData = (string,key='@BeeCell@')=>{
    
    // console.log(key)
        const CryptoJS = require('crypto-js');
        var iv = CryptoJS.lib.WordArray.random(16);// the reason to be 16, please read on `encryptMethod` property. 
        var salt = CryptoJS.lib.WordArray.random(256);
        var iterations = 1;
        var encryptMethodLength = (encryptMethodLengthFunction()/4);// example: AES number is 256 / 4 = 64
        var hashKey = CryptoJS.PBKDF2(key, salt, {'hasher': CryptoJS.algo.SHA512, 'keySize': (encryptMethodLength/8), 'iterations': iterations}); 
        var encrypted = CryptoJS.AES.encrypt(string, hashKey, {'mode': CryptoJS.mode.CBC, 'iv': iv});
        var encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext); 
        //console.log(iv);
        var output = {
            'ciphertext': encryptedString,
            'iv': CryptoJS.enc.Hex.stringify(iv),
            'salt': CryptoJS.enc.Hex.stringify(salt),
            'iterations': iterations
        }; 
    //  console.log(JSON.stringify(output));
        return( CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(output))));
     
 
  }
/** seprate domain url from params and set all paams in object */
  export const ConvertUrlToObject = (fullUrl,payload={}) =>{
        let urlArray=fullUrl.split('?');
        var paramsObj={};
        if(urlArray[1])
        {
            var innerList=urlArray[1].split('&');
            for(var i=0;i<innerList.length;i++)
            {
                var keyValueGen=innerList[i].split('=');
                paramsObj[keyValueGen[0]]=keyValueGen[1]
            }
        }
        
        if(typeof payload == 'string'){
            payload = JSON.parse(payload);
        }
        paramsObj={...paramsObj, ...payload}
        var payloadString=JSON.stringify(paramsObj);


        let domain=urlArray[0];
        return {"domain":domain, "payload":payloadString, "payloadObj":paramsObj}
  }

  export const ReplaceTagStringWithElement = (fullText,tagString,element,newText='') => {

    //const fullText = 'Your Child "taha" finished "<courseLink> Drawing </courseLink>" course ';
    //const tagString = 'courseLink'
    const start = '<'+tagString+'>';
    const end = '</'+tagString+'>';
    const pattern = start + "(.*)" + end;
    //const result = fullText.match(new RegExp(pattern));
    const textArray = fullText.split(new RegExp(pattern));
    if(textArray.length <= 1){
        return fullText;
    }
    //console.log(textArray);    
    let newElement = React.cloneElement(element, { key:uuid()}, newText? newText :textArray[1])
    //return newElement;
    const reactArr = [textArray[0], newElement, textArray[2]]
    return (
        reactArr
    )
  } 

  export const ReplaceMutipleTagStringWithElement = (fullText,tagString,element,newText=[]) => {

    //const fullText = 'Your Child "taha" finished "<courseLink> Drawing </courseLink>" course ';
    //const tagString = 'courseLink'
    const start = '<'+tagString[0]+'>';
    const end = '</'+tagString[0]+'>';
    const pattern = start + "(.*)" + end;
    //const result = fullText.match(new RegExp(pattern));
    const textArray = fullText.split(new RegExp(pattern));
    if(textArray.length <= 1){
        return fullText;
    }
    console.log(textArray);    
    let newElement = React.cloneElement(element[0], { key:uuid()}, textArray[1]);



    return ;
    //return newElement;

        let contents = [textArray[0], newElement, textArray[2]];
        for(let i=1; i<tagString.length;i++){            
            let next = 2;
            if(i>=2){
                next = (i*2) +1;
            }
            contents = [...contents, ...ReplaceTagStringWithElement(contents[next], tagString[i], element[i], newText[i]?newText[i]:'')];
            console.log(contents);
        }

        return contents;










        tagString.shift();
        element.shift();

        if(newText.length>0){
            newText.shift();
        }
        
        let otherText = ReplaceTagStringWithElement(textArray[2], tagString[0], element[0], newText[0]?newText[0]:'');
        console.log(otherText[0])
        return [textArray[0], newElement , otherText[0]];
    

  }   

  export const splitStringByTag = (fullText,tagString) => {

    //const fullText = 'Your Child "taha" finished "<courseLink> Drawing </courseLink>" course ';
    //const tagString = 'courseLink'
    const start = '<'+tagString+'>';
    const end = '</'+tagString+'>';
    const pattern = start + "(.*)" + end;
    //const result = fullText.match(new RegExp(pattern));
    const textArray = fullText.split(new RegExp(pattern));
    if(textArray.length <= 1){
        return fullText;
    }
    //console.log(textArray);    
    return textArray[2] 

  } 

  export const ReplaceStringWithElement = (fullText,stringToReplace,element) => {


    const textArray = fullText.split(stringToReplace);
    if(textArray.length <= 1){
        return fullText;
    }
    //console.log(textArray);    
    //return newElement;
    const reactArr = [textArray[0], element, textArray[1]]
    return (
        reactArr
    )
  }   

  export const DownloadAuthResponse = (AuthObj,url,fileName, beforeCallback=null, callback=null) => {

    if(callback){
        beforeCallback();
    }     
    //AuthObj.authorizedRequest({'Content-Type': 'application/octet-stream','withoutDecryption':1},{ responseType: 'blob' }).post(url).then((res)=> {
    AuthObj.authorizedRequest({'Content-Type': 'application/x-www-form-urlencoded','withoutDecryption':1}).post(url).then((res)=> {
        const contentType = "application/octet-stream";
        const b64Data = res.data.payload.contents;
        const blob = base64StringToBlob(b64Data, contentType);
        
        const downloadUrl = createObjectURL(blob);      
        //const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));

        const link = document.createElement('a');

        link.href = downloadUrl;

        link.setAttribute('download', fileName); //any other extension

        document.body.appendChild(link);

        link.click();

        link.remove();
        
        if(callback){
            callback();
        }
   
    }).catch(err => {
        console.log(err);
        if(callback){
            callback();
        }        
    })
  }


  export const StringFormat = function() {
    const argsLength = arguments.length - 1;
    let newString = arguments[0];
    for (var i = 0; i < argsLength; i++) {
        var regexp = new RegExp('\\{'+i+'\\}', 'gi');
        newString = newString.replace(regexp, arguments[i+1]);
    }
    return newString;
};

    export const trim = (str, ch) => {
        var start = 0, 
            end = str.length;

        while(start < end && str[start] === ch)
            ++start;

        while(end > start && str[end - 1] === ch)
            --end;

        return (start > 0 || end < str.length) ? str.substring(start, end) : str;
    }

    export const otherLanguageCourseName = (course,language) => {
        let courseName = course.displayname;
        if(language == 'ar'){
            let row = course?.customfields?.find( field => field?.shortname == 'cnar');
            courseName = row.value || courseName;
        }

        return courseName;

    }

    export const getCourseNameBasedOnLanguage = (courseData, language) => {
        let courseName = courseData.displayname;
        if(language == 'ar'){
            let courseItemLang = courseData?.customfields.find( item => item.shortname == 'cnar');
            courseName = courseItemLang?.value || courseName ;
        }
        return courseName;
    }

    export const usernameSuggestions = async(var1, var2) => {
        let sugg = '';

        // const max = 1000;
        // const min = 100;
        // const randNum = Math.floor(Math.random() * (max - min + 1)) + min;
        const randNum = '';
        sugg +=var1+''+var2+randNum+', ';
        sugg +=var1+'_'+var2+randNum+', ';
        sugg +=var1+'.'+var2+randNum+', ';
        //sugg +=var1.substring(0,3)+''+var2.substring(0,3)+randNum+', ';
        //sugg +=var1.substring(0,3)+'_'+var2.substring(0,3)+randNum+', ';
        //sugg +=var1.substring(0,3)+'.'+var2.substring(0,3)+randNum+', ';

        return sugg;
    }

    export const ccPhoneFormat = (phoneNumber) => {
        const format = '(xxx) xx-xxx-xxxx';
        let result = format;
        phoneNumber = phoneNumber.replace('+', '');
        for(let x=0;x<phoneNumber.length;x++){
            result = result.replace('x', phoneNumber[x]);
        }
        result = result.replace(/x/g, '');// replace remaining chars
        return result;
    }

    export const errorFocus = (errorsList={}) => {           
            if(Object.keys(errorsList)[0]){
                const err_first_element = document.querySelector(
                    `[name='${Object.keys(errorsList)[0]}']`
                  )      
                  if(err_first_element){                    
                    err_first_element.scrollIntoView({ behavior: "smooth", block: "center" });
                  }     
            }                    
    }
export const editProfile = (setEdit,isEmailMobile=0) =>{
    
       //edit profile
       //document.querySelector('.reg-input').classList.remove('readonly-field');
        //window.$('.reg-input').removeClass('readonly-field');
    
        // if(isEmailMobile ==1){
        //     window.$('#email').addClass('readonly-field');
        // }
        // else        
        // if(isEmailMobile ==2){
        //     window.$('#mobile').addClass('readonly-field');
        // }

        //window.$(".reg-input").removeAttr("readonly");
        //window.$(".user-dob").removeAttr("disabled");
        window.$(".parent_email").prop('readonly', true);
        //window.$(".select-date").removeAttr("disabled");
        window.$('.save-profile').removeClass('hide');
        window.$('.eye-icon').removeClass('profile-eye');
        window.$('.edit-icon').addClass('hide');
        window.$('.profile-user-name').addClass('hide');
        window.$('.upload-img').removeClass('hide');
        window.$('#usernameIcon2').removeClass('hide');
        window.$('#usernameIcon1').addClass('hide');
        window.$('#parent_email').addClass('parent_email2');
        window.$('#parent_email').removeClass('parent_email');
        setEdit(true);
}

const  findPosition = (obj) => {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return [currenttop];
    }
  }

  export const scrollTo = (elementId) => {
    window.scroll(0, findPosition(document.getElementById(elementId)));   
  }


  export const getQueryObject = (queryString='') => {
    if(!queryString){
        queryString = document.location.search;
    }

    if(queryString.length > 0){
        queryString = queryString.slice(1);
    }
    let queryStringObj = {}
    queryString.split('&').forEach( keyVal => {
        let keyValArr = keyVal.split('=');
        queryStringObj[keyValArr[0]] = keyValArr[1] || '';

    });

    return queryStringObj
  }

  export const queryObjectToString = (queryObject) => {
    let queryString = '';

    for (const [key, value] of Object.entries(queryObject)) {
        queryString+=key+'='+value+'&';
    }    
    let length = queryString.length;
    if(length >0){
        // to remove last &
        queryString = '?'+queryString.slice(0, -1);
    }
    return queryString;


  }  

  export const updateQueryStringValue = (paramName,paramValue='',queryString='',updateGlobal = true,returnAsString=false) => {
        let queryStringObj = getQueryObject(queryString);
        queryStringObj[paramName] = paramValue;
        queryString = queryObjectToString(queryStringObj);
        if(updateGlobal){            
            document.location.search = queryString;
        }
        if(returnAsString){
            return queryString;
        }        
        return queryStringObj;
  }

  export const removeQueryStringParam = (paramName,queryString='',updateGlobal = true,returnAsString=false) => {
    let queryStringObj = getQueryObject(queryString);
    delete queryStringObj[paramName] ;
    queryString = queryObjectToString(queryStringObj);
    if(updateGlobal){        
        document.location.search = queryString;
    }
    if(returnAsString){
        return queryString;
    }
    return queryStringObj;
}  


export const escapedNewLineToLineBreakTag = (string) => {
    return string.split('\n').map((item, index) => {
      return (index === 0) ? item : [<br key={index} />, item]
    })
  }
  export const htmlBrToReacBr = (string) => {
    return string.split(/<br\s*\/>/).map((item, index) => {
      return (index === 0) ? item : [<br key={index} />, item]
    })
  }  

  export const saveTrafficParams = (trafficType='all',saveIfNotExist=false) => {

    const cookies = new Cookies();
    const savedThirdPartySrc = cookies.get('thirdPartySrcKey');
    const savedTrafficSrc = cookies.get('trafficSrcKey');
        const trafficSrcParams = ['gclid','fbclid','clickid'];
    const searchParams = new URLSearchParams(document.location.search);
    let sourceKey, sourceVal = '';
    
    if((saveIfNotExist==true || !savedTrafficSrc) && (trafficType == 'all' || trafficType == 'social') ){
        for(let i=0;i<trafficSrcParams.length;i++){
        let paramName = trafficSrcParams[i];
        if(searchParams.get(paramName)){
            sourceKey = paramName;
            sourceVal = searchParams.get(paramName);        
            break;
        }
        }
    }
    ///-------get third party
    const trafficSrcParamsThirdParty = ['dm'];
    const searchParamsThirdParty = new URLSearchParams(document.location.search);
    let sourceKeyThirdParty, sourceValThirdParty = '';
    if((saveIfNotExist==true || !savedThirdPartySrc) && (trafficType == 'all' || trafficType == 'thirdParty') ){
        for(let i=0;i<trafficSrcParamsThirdParty.length;i++){
        let paramNameThirdParty = trafficSrcParamsThirdParty[i];
        if(searchParamsThirdParty.get(paramNameThirdParty)){
            sourceKeyThirdParty = paramNameThirdParty;
            sourceValThirdParty = searchParamsThirdParty.get(paramNameThirdParty);        
            break;
        }
        }    
    }

    if(sourceKey && sourceVal){
      let maxAge = 86400 // 24 hours
      cookies.set('trafficSrcKey', sourceKey, { path: '/' , maxAge});
      cookies.set('trafficSrcVal', sourceVal, { path: '/' , maxAge});
    }
    if(sourceKeyThirdParty && sourceValThirdParty){
      let maxAge = 86400 // 24 hours
      cookies.set('thirdPartySrcKey', sourceKeyThirdParty, { path: '/' , maxAge});
      cookies.set('thirdPartySrcVal', sourceValThirdParty, { path: '/' , maxAge});
    }


  }  

  export const getThirdPartySource = () => {
    const cookies = new Cookies();
    const src = cookies.get('thirdPartySrcKey');
    if(src){
        return src;
    }
    return null;
  }  


  export const getTrafficSource = () => {
    const cookies = new Cookies();
    const src = cookies.get('trafficSrcKey');
    if(src){
        return src;
    }
    return null;
  }    


  export const updateUrlOnSignup = (step=0) => {

        let pathname = localStorage.getItem('basename')+'/userRegistration';
        let currentUrl = window.location.origin + pathname; //+ window.location.search;
        //let prefix = currentUrl.includes("?") ? '&' : '?';
        let extraValues = '';
        if(step == 0){
            extraValues += '?page=main';
        }
        else if(step == 1){
            extraValues += '?page=signup';
        }
        else if(step ==2){
            extraValues += '?page=verify';
        }
        else if(step ==3){
            extraValues += '?page=success';
        }        
        currentUrl += extraValues;
        const nextState = { additionalInformation: extraValues };
        window.history.pushState(nextState, document.title, currentUrl);
  }     

const Functions = {

    formatDate : formatDate,
    getAge : getAge,
    fileInfoFromPath : fileInfoFromPath,
    getFileIcon:getFileIcon,
    subtractYears:subtractYears,
    EncryptData,
    DecryptData,
    ConvertUrlToObject,
    ReplaceTagStringWithElement,
    ReplaceMutipleTagStringWithElement,
    ReplaceStringWithElement,
    DownloadAuthResponse,
    StringFormat,
    trim,
    splitStringByTag,
    otherLanguageCourseName,
    getCourseNameBasedOnLanguage,
    usernameSuggestions,
    ccPhoneFormat,
    errorFocus,
    editProfile,
    scrollTo,
    getQueryObject,
    queryObjectToString,
    updateQueryStringValue,
    removeQueryStringParam,
    escapedNewLineToLineBreakTag,
    htmlBrToReacBr,
    saveTrafficParams,
    getThirdPartySource,
    getTrafficSource,
    updateUrlOnSignup

};

export default Functions;