import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import {useGoogleLogin} from '@react-oauth/google';
import uuid from 'react-uuid';

  const GoogLogin = (props) =>{

    const Auth = useContext(AuthContext);
    const navigate = useNavigate();

    const login = useGoogleLogin({
      onSuccess: codeResponse => {
        props.setLoading(true);
        const accessToken = codeResponse?.access_token;
        if(accessToken){
          const dataObj = {accessToken,getEmailOnly: props?.getEmailOnly?1:0 };
        
          Auth.authorizedRequest({'Content-Type':'application/x-www-form-urlencoded'}).post('checkGoogleLogin',dataObj).then(res => {   
            //console.log(res.data.payload);
            props.setLoading(false);

            if(props?.registerCalback && props?.getEmailOnly){
              props.registerCalback(res.data.payload?.email ||'',accessToken || '');
              return;
            }              

            if(res.data.payload?.action == 'register'){  
              window.$('#login').modal('hide');   


              if(props?.registerCalback){
                  props.registerCalback(res.data.payload?.email ||'', accessToken || '');
              }
              else{
                if(props?.preRedirect){
                  props.preRedirect();
                  setTimeout(() => {
                    navigate('/register/'+uuid(),{ state: res.data.payload});            
                  }, 2000);                   
                }
                else{
                  navigate('/register/'+uuid(),{ state: res.data.payload});            
                }
                  
              }
              
            }
            else if(res.data.payload?.action == 'login'){
              if(props?.preRedirect){
                props.preRedirect();
                setTimeout(() => {
                  Auth.successLoginProcess(res, props?.successLoginRedirect || '')  
                }, 2000);                 
              }  
              else{            
                Auth.successLoginProcess(res, props?.successLoginRedirect || '')
              }
              //console.log(res.data.payload)
            }
          }).catch(err => {
            props.setLoading(false);
          })                   
        }
      },
      //flow: 'auth-code',
    });
    return (
          React.cloneElement(props.children, {
            onClick: login,
          })
        

    )
  }

export default GoogLogin;