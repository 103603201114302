import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import reportWebVitals from './reportWebVitals';
// import About from './about.js'
// import { Route,  BrowserRouter as Router, Routes} from 'react-router-dom' 


// import './assets/public/css/platform.css';
// import './assets/public/css/navbar.css';
// import './assets/public/css/style.css';

// import './assets/public/js/jquery-3.4.1.min.js'
// import './assets/public/js/bootstrap.min.js'
// import './assets/public/js/navbar.js'
// import './index.css';
//import './assets/public/css/bootstrap.min.css'
import './assets/public/css/font-awesome-4.7.0/css/font-awesome.min.css'
import './assets/public/css/style.min.css'
import './assets/public/css/platform.min.css'
import './assets/public/css/navbar.min.css'
import './index.min.css'
import './index2.js'

localStorage.setItem('basename','');
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode></React.StrictMode>
    <App />
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

