import React, { memo, useEffect } from "react";
import {useContext, useState} from 'react'; 
import LoadingButton from '../elements/loadingButton';
import InputValidations from '../../helpers/inputValidations';
import {AuthContext} from '../../context/AuthContext'
import {NavLink, useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/languageContext';
import '../../assets/css/forms.css';
import '../../assets/css/campTheme.css';
import '../../assets/css/modals.css';
import FbLogin from "../elements/facebookLogin";
import GoogLogin from "../elements/googleLogin";
import {GoogleOAuthProvider} from '@react-oauth/google';
import googleConfig from '../config/google.js'
import { ReplaceTagStringWithElement } from "../../helpers/functions";
import Cookies from "universal-cookie";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import AppleLoginBtn from "../elements/appleLogin.js";

const GOOGLECLIENTID = googleConfig.GOOGLE_CLIENT_ID;

const LoginModal = () => {
    const cookies= new Cookies();
    let isVas = cookies.get('isVas') || 0;
    const lagKeywords = React.useContext(UserContext);     
    const Auth = useContext(AuthContext);
    let initialFormStates = {
        email: '',
        password: '',
        formErrors: {email: '', password: ''},
        emailValid: true,
        passwordValid: true,
        formValid: true,
        formErrorMessage:''
      };
    const [isLoading, setLoading] = useState(false);
    const [formStates, setFormStates] = useState(initialFormStates);
    const [fontIcon, setFontIcon] = useState(faEye);
    const validateLogin = async(e) =>{
        e.preventDefault();    
        setFormStates(initialFormStates);        
        
        let emailValid =  InputValidations.required(formStates.email,'Email');
        //if(emailValid.isValid)
        //    emailValid =  InputValidations.isEmail(formStates.email);
        let passValid = InputValidations.password(formStates.password,'Password');        
        formStates.formErrors.email = emailValid.message;
        formStates.formErrors.password = passValid.message;
        formStates.emailValid = emailValid.isValid;
        formStates.passwordValid = passValid.isValid;
        formStates.formValid = (formStates.emailValid && formStates.passwordValid);
        formStates.formErrorMessage = '';
        //console.log(formStates);
        setFormStates({...formStates}); 

        if(formStates.formValid){
            setLoading(true);
            //calling login api
            let res = await Auth.login(formStates.email, formStates.password);
            if(res?.status == 2){
              setFormStates({...formStates, formErrorMessage:lagKeywords['incorrect_username_password']}); 
            }
            else if(res?.status==3){
              setFormStates({...formStates, formErrorMessage:lagKeywords['username_not_exist']});               
            }
            else if(res?.status==800){ // logged in from other device
              setFormStates({...formStates, formErrorMessage:ReplaceTagStringWithElement(lagKeywords['login_other_device'], 'navlink',
              <a onClick={
                ()=>{
                    setFormStates({...formStates, formErrorMessage:lagKeywords['processingDots']});
                    Auth.logoutFromAllDevices(res.id, formStates.password).then( res => {                      
                        setFormStates({...formStates, formErrorMessage:''});                        
                    });
                }
              }/>

              )});               
            }            
            else if(res?.status==900){ // reach total number of devices allowed to login
              setFormStates({...formStates, formErrorMessage:lagKeywords['device_not_allowed']});               
            }                        
            setLoading(false);
            
        }
        
/*
        setTimeout(() => {
                setLoading(false);
        }, 3000);
*/
    }

// Password Toggling 
const handleIcon = () =>{

      var input = window.$('#pass');
      
      if (input.attr("type") == "password") {
      input.attr("type", "text");
      setFontIcon(faEyeSlash);
      } else {
      input.attr("type", "password");
      setFontIcon(faEye);
      }
      input.focus();
      };
  
    const navigate = useNavigate();
    const navigateRegister = (e) =>{
      e.preventDefault();
      window.$('#login').modal('hide');      
      //navigate('/userRegistration');
      navigate('/userRegistration');
    }

    const showForgetPasswordModal = () => {
      window.$('.modal').modal('hide');
      window.$('#forget-password-modal').modal('show');
    }


    useEffect(()=>{

        // const keyDownHandler = event => {
    
        //   console.log(event.target.classList.contains('modal-open'));
        //   if (event.key === 'Enter' ) {
        //     event.preventDefault();
        //     document.getElementById('login-btn')?.click();
        //     //handleSubmit();
        //   }
        // };

        // document.addEventListener('keydown', keyDownHandler);

        // return () => {
        //   document.removeEventListener('keydown', keyDownHandler);
        // };
        window.$(document).keydown(function(e) {
          //console.log(e.keyCode)
          if (window.$("#login").hasClass('in') && (e.keyCode  == 13 || e.which == 13)) {
            //console.log('here')
            //document.getElementById('login-btn')?.click();
            window.$('button#login-btn')?.click();
          }
        });

  },[])  

 

  


    return (
        <div id="login" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button data-dismiss="modal" className="close">&times;</button>
              <form className="form-modal" method='get' onSubmit={validateLogin}>
                { !isVas && 
                <>
                  <div className="form-group">
                  <FbLogin className={`hide`} setLoading={setLoading}>
                      <div className="reg-button gray-bordered">                
                        <img src={process.env.PUBLIC_URL + "/assets/images/facebook-icon.svg"} alt="facebook" style={{width:'30px'}} />
                        <p>{lagKeywords.reg_by}</p>
                      </div>
                    </FbLogin>
                  </div>
                <div className="form-group">
                <GoogleOAuthProvider clientId={GOOGLECLIENTID} className="social-login-container">
                  <GoogLogin setLoading={setLoading}>
                    <div className="reg-button gray-bordered">                
                      <img src={process.env.PUBLIC_URL + "/assets/images/google-icon.svg"} alt="google" style={{width:'30px'}}  />
                      <p>{lagKeywords.using_google}</p>
                    </div>
                  </GoogLogin>
                </GoogleOAuthProvider>
                </div>

                { 
                  <div className="" style={{margin:'10px',marginTop:'0px'}}>
                      <div>
                          <AppleLoginBtn  setLoading={setLoading}/>
                      </div>
                  </div>
                }

                </>
                }
                <div className="horizontal-line">
                  <hr style={{width:'90%', color:'#999999',margin: '0 auto'}} /><span className="or">{lagKeywords.or} </span>
                </div>
                <div className={`form-group ${!formStates.emailValid?'error-input':''}`}>
                    {/* <label className="label-mawaheb">{lagKeywords.EmailOrUsername}</label>
                    <input type="text" value={formStates.email} onChange={(e)=>{setFormStates({...formStates, email:e.target.value})}} className={`form-control input-lg input-form `} placeholder={`${lagKeywords['email']}`} />
                    <p className='error-message'>{lagKeywords[formStates.formErrors.email]}</p> */}
                    <div className="field">
                        <label htmlFor="name" className="placeholder">{lagKeywords.Email_username_edit_profile}</label>
                        <input autoComplete="on" type="text" id="name" className="login-input form-control input-lg input-form" value={formStates.email} placeholder=" " onChange={(e)=>{setFormStates({...formStates, email:e.target.value})}}/>
                        <p className='error-message'>{lagKeywords[formStates.formErrors.email]}</p> 
                    </div>
                </div>
                <div className={`form-group ${!formStates.passwordValid?'error-input':''}`}>
                <div className="field">
                        <label htmlFor="pass" className="placeholder">{`${lagKeywords['password']}`}</label>
                        <input type="password" id="pass" className="login-input form-control input-lg input-form" value={formStates.password} placeholder=" " onChange={(e)=>{setFormStates({...formStates, password:e.target.value})}} />
                        <p className='error-message'>{lagKeywords[formStates.formErrors.password]}</p>
                        <p className='error-message'>{formStates.formErrorMessage}</p> 
                        {/* <span toggle="#pass" className="fa fa-fw fa-eye field-icon toggle-password"></span> */}
                        <FontAwesomeIcon onClick={handleIcon} icon={fontIcon} className="field-icon toggle-password"/> 
                        {/* {fontIcon == faEyeSlash && <FontAwesomeIcon onClick={handleIcon} icon={faEyeSlash} className="field-icon toggle-password"/> } */}

                </div>

                    {/* <input type="password" value={formStates.password} onChange={(e)=>{setFormStates({...formStates, password:e.target.value})}} className="form-control input-lg input-form" placeholder={`${lagKeywords['password']}`}/>
                    <p className='error-message'>{lagKeywords[formStates.formErrors.password]}</p>
                    <p className='error-message'>{lagKeywords[formStates.formErrorMessage]}</p> */}
                </div>
                <NavLink onClick={showForgetPasswordModal} className="forgot-pass">{lagKeywords.forgot_password}</NavLink>                
                <LoadingButton id={`login-btn`} loading={isLoading.toString()} type="submit" className="submit-button-auto" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{lagKeywords.login}</LoadingButton>
              </form>
            
              {
                isVas ?
                <div className="social-login-container">
                  <LoadingButton id={`login-btn`} onClick={()=> {navigate('/authMobile');window.$('#login').modal('hide');}} type="button" className="submit-button-auto2"  >{lagKeywords.complete_your_reg}</LoadingButton>
                </div> :''
              }
              {
                !isVas && <div className="register-now" data-target="#userReg">{lagKeywords.new_user} <NavLink id={`signup-btn`} onClick={navigateRegister}>{lagKeywords.register_now}</NavLink></div>
              }
            </div>
          </div>
        </div>  
      </div> 
      
            
    )    
}


export default memo(LoginModal);