import React from "react";
import {useContext, useState} from 'react'; 
import LoadingButton from '../elements/loadingButton';
import InputValidations from '../../helpers/inputValidations';
import {AuthContext} from '../../context/AuthContext'
import {UserContext} from '../../context/languageContext';
import '../../assets/css/forms.css';
import { useNavigate } from "react-router-dom";

const ForgetPasswordModal = () => {
    const goto = useNavigate();
    const lagKeywords = React.useContext(UserContext);     
    const Auth = useContext(AuthContext);
    let initialFormStates = {
        email: '',
        formErrors: {email: ''},
        emailValid: true,
        formValid: true,
        formErrorMessage:''
      };
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [formStates, setFormStates] = useState(initialFormStates);


    const validateForgetPassword = async(e) =>{
        e.preventDefault();    
        setFormStates(initialFormStates);        
        
        let emailValid =  InputValidations.required(formStates.email,'Email');
        if(emailValid.isValid)
            emailValid =  InputValidations.isEmail(formStates.email);   
        formStates.formErrors.email = emailValid.message;
        formStates.emailValid = emailValid.isValid;
        formStates.formValid = (formStates.emailValid);
        formStates.formErrorMessage = '';
        //console.log(formStates);
        setFormStates({...formStates}); 

        if(formStates.formValid){
            setLoading(true);
            //calling login api
            let res = await Auth.ForgetPasswrod(formStates.email);
            if(res ==0){
              setFormStates({...formStates, formErrorMessage:lagKeywords['data_saved_faild']}); 
            }
            else if(res ==3){
              setFormStates({...formStates, formErrorMessage:lagKeywords['email_not_exist']}); 
            }            
            else{
              setSuccess(true);
              window.$('#forget-password-modal').on('hidden.bs.modal', function () {
                setSuccess(false); // reset state
              })
            }
            setLoading(false);
            
        }
        
    }

    const gotoAuthMobile = () => {
      window.location.href = localStorage.getItem('basename')+'/resetMobile';
      //window.$('#forget-password-modal').modal('hide');
    }


    return (
        <div id="forget-password-modal" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">

          <div style={{"textAlign":"center","color":"#fff", "backgroundColor":"#f59f39","fontSize":"25px","padding":"15px"}} className="modal-header">
              {lagKeywords.forget_password}
          </div>
          <button data-dismiss="modal" className="close">&times;</button>         
            <div className="modal-body"> 

            {isSuccess != true &&                   
              <form className="form-modal" method='get' onSubmit={validateForgetPassword}>
              
                <div className={`form-group ${!formStates.emailValid?'error-input':''}`}>
                
                    <div className="field">
                        <label htmlFor="name" className="placeholder">{lagKeywords.email}</label>
                        <input autoComplete="on" type="text" id="name" className="login-input form-control input-lg input-form" value={formStates.email} placeholder=" " onChange={(e)=>{setFormStates({...formStates, email:e.target.value})}}/>
                        <p className='error-message'>{ formStates.formErrorMessage || lagKeywords[formStates.formErrors.email]}</p> 
                    </div>
                </div>

                <LoadingButton  loading={isLoading.toString()} type="submit" className="submit-button-auto reset-btn btn-width2" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{lagKeywords.reset_password}</LoadingButton>
                <LoadingButton  onClick={gotoAuthMobile} loading={isLoading.toString()} type="button" className="submit-button-auto reset-btn btn-width2" loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Processing" >{lagKeywords.forgot_password_mobile}</LoadingButton>
              </form>

            }
            {
              isSuccess == true && <div>{lagKeywords['reset_password_request_success']}</div> 
            }
              
            
            </div>
          </div>
        </div>  
      </div>        
    )    
}


export default ForgetPasswordModal;