import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { AuthContext } from '../../context/AuthContext';
import facebookConfig from '../config/facebook.js'

const APPID = facebookConfig.APPID;

  const FbLogin = (props) =>{

    const Auth = useContext(AuthContext);
    const navigate = useNavigate();

    const fbLogin = () => {
      window.FB.login(function (response) {
          if (response.authResponse) {
              // Get and display the user profile data
              window.FB.api('/me', {locale: 'en_US', fields: 'name,email,picture'},
              function (response) {
                responseFacebook(response);
              });              
              
          } else {
             // document.getElementById('status').innerHTML = 'User cancelled login or did not fully authorize.';
          }
      }, {scope: 'public_profile'});
  }

    const responseFacebook = (response) => {
      //console.log(response);
      //&& response?.email
        if(response && response?.id){
          const fbAuthParam = window.FB.getAuthResponse();
          //var access_token =   response?.authResponse?.accessToken || '';//window.FB.getAuthResponse()['accessToken'];
          const [fname, lname] = response.name.split(" ");
          const dataObj = {
            fname,
            lname,
            email : response.email,
            id : response.id,
            accessToken : fbAuthParam['accessToken'],//access_token,//response.accessToken,
            expiresIn : fbAuthParam['expiresIn'],//response.expiresIn,
            accessRokenExpiration : fbAuthParam['data_access_expiration_time']//response.data_access_expiration_time
          };
          //console.log(dataObj);

          if(props?.setLoading){
            props.setLoading(true);
          }
          Auth.authorizedRequest({'Content-Type':'application/x-www-form-urlencoded'}).post('checkFacebookLogin',dataObj).then(res => {
            //console.log(res.data.payload);
            if(props?.setLoading){
              props.setLoading(false);
            }
            if(res.data.payload?.action == 'register'){  
              window.$('#login').modal('hide');  
              // if(res.data.payload?.url && props?.getRedirectUrl){
              //   navigate(res.data.payload.url);
              //   return;
              // } 
              if(props?.registerCalback){
                props.registerCalback(res.data.payload?.email ||'',dataObj?.accessToken || '');
              }
              else{              
                navigate('/register/'+uuid(),{ state: res.data.payload});            
              }
            }
            else if(res.data.payload?.action == 'login'){
              Auth.successLoginProcess(res, props?.successLoginRedirect || '')
              //console.log(res.data.payload)
            }
          }).catch(err => {
            if(props?.setLoading){
              props.setLoading(false);
            }
          })
        }
        else
        {
          console.log(response);
        }
      }


    return (
      <div
      className={props?.className ? props.className:''}
      onClick={()=>{
        fbLogin();
        if(props?.onClick){
          props.onClick();
        }
      }}
      >
        {props.children}
        </div>
    )  
    // return (
    //   <FacebookLogin
    //     //appId="471384089680374"
    //     appId={APPID}
    //     autoLoad={false}
    //     fields="name,email,picture"
    //     // scope="public_profile,user_friends,user_actions.books"
    //     callback={responseFacebook}
    //     render={renderProps => (React.cloneElement(props.children, {
    //         onClick: renderProps.onClick
    //       })) }
    //   />
    // )
  }

export default FbLogin;