import {saveTrafficParams, getThirdPartySource} from '../../helpers/functions';
saveTrafficParams('thirdParty');

let config={

    GOOGLE_CLIENT_ID:'205399417444-iip3ki86k2ee6hd8rp34u79scn6d098d.apps.googleusercontent.com',
    GOOGLE_TAG_AD_WORD: 'AW-11178320075',
    GOOGLE_TAG_CONVERSION_ID: 'Xdw1COCk7p8YEMvBntIp',
    GOODLE_TAG_SUBSCRIBE_EVENT_SUCCESS:'Subscribe New',
    GOODLE_TAG_SUBSCRIBE_EVENT_FAILED:'SignUp',
    GOODLE_TAG_SUBSCRIBE_EVENT_UNDER_PROCESSING:'SignUp',
    GOOGLE_TAG_PAGE_VIEW_ID: '2GEhCNipz9gYEMvBntIp',
    GOOGLE_TAG_CONVERSION_SigupID: 'Hz3yCMLPmKAYEMvBntIpShareif',
    GOOGLE_TAG_CONVERSION_INTERNALLY:"AII2CPSb6ugYEMvBntIp",
    GOOGLE_TAG_CONVERSION_LABEL:"Purchase", 
    GOOGLE_TAG_THEME_REGISTER : "SignUp",   
    GOOGLE_TAG_THEME_SUCESS : "Purchase",
    GOOGLE_TAG_THEME_REGISTER_EVENT : "SignUp",   
    GOOGLE_TAG_THEME_SUCESS_EVENT : "Purchase",
}

if(getThirdPartySource() == 'dm'){
  console.log('google updated');
  config={
    GOOGLE_CLIENT_ID:'205399417444-iip3ki86k2ee6hd8rp34u79scn6d098d.apps.googleusercontent.com',
    GOOGLE_TAG_AD_WORD: 'AW-11393556626',
    GOOGLE_TAG_CONVERSION_ID: 'IBWDCMe1kvcYEJLB77gq',
    GOODLE_TAG_SUBSCRIBE_EVENT_SUCCESS:'Subscribe New',
    GOODLE_TAG_SUBSCRIBE_EVENT_FAILED:'SignUp',
    GOODLE_TAG_SUBSCRIBE_EVENT_UNDER_PROCESSING:'SignUp',
    GOOGLE_TAG_PAGE_VIEW_ID: '2GEhCNipz9gYEMvBntIp',
    GOOGLE_TAG_CONVERSION_SigupID: 'ladvCPmlkvcYEJLB77gq',
    GOOGLE_TAG_CONVERSION_INTERNALLY:"IBWDCMe1kvcYEJLB77gq",
    GOOGLE_TAG_CONVERSION_LABEL:"Purchase",     
    GOOGLE_TAG_THEME_REGISTER_EVENT : "SignUp",   
    GOOGLE_TAG_THEME_SUCESS_EVENT : "Purchase",
  }
}

export default config