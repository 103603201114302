import '../../assets/css/modals.css'; 

const MessageModal = (props) => {

  const lang = props?.lang || '';
  const modalId = props.id ? props.id:'modal';
  const closeModel = (modalId) => {
    window.$("#"+modalId).modal('hide');
  }

  const handleOkCallback =() => {
    if(props.okCallback){
      let params = props?.okParams || [];
       props.okCallback(...params);
    } 
    else{  
      closeModel(modalId);
    }
  }

  window.$("#"+modalId).on('hide.bs.modal', function(e){
    e.stopPropagation();
    e.stopImmediatePropagation();
    if(props.onclose){
      props.onclose();
    }
  });  
  
    return (
        <div id={modalId} className="modal fade" role="dialog">
        <div className={`modal-dialog ${props['modal-class-size'] || ''}`}>
          <div className="modal-content2">
            <div className="modal-header">
            <button data-dismiss="modal" className="close2">&times;</button>
              {/* {props?.title == 'no_title' ? '' : (props?.title || 'Message')} */}
            </div>
            <div className="modal-body" style={{direction:lang=='ar'?'rtl':'ltr'}}>              
              {props.children}
            </div>
            <div className="modal-footer" style={{direction:lang=='ar'?'rtl':'ltr'}}>
              <button type="button" onClick={handleOkCallback} className="btn btn-primary">{props?.okLabel || 'OK'}</button>           
            </div>
          </div>
        </div>  
      </div>        
    )    
}


export default MessageModal;