import loadImg from '../../assets/images/loading-m.svg'

const Image = (props) => {

    const onLoadImage = (e) => {
        const realSrc = e.target.getAttribute('data-src');
        e.target.setAttribute('src',realSrc);

    }
    return <img 
                style={props?.style || {}} 
                width={props?.width || ''} 
                height={props?.height || ''} 
                className={props?.className || ''} 
                id={props?.id || ''} 
                name={props?.name || ''} 
                // onLoad={onLoadImage} 
                //src={loadImg} 
                src = {props.src}
                data-src={props.src} 
                alt={props?.alt || props.src}
                onError={props?.onError}
                loading="lazy"
            />
}

export default Image