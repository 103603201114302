import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
// import AppleLogin from 'react-apple-login'
import AppleSignin from 'react-apple-signin-auth';
import uuid from 'react-uuid';
import appleConfig from '../config/apple.js'
import { faApple } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from '../../context/languageContext.js';

let domain = window.location.origin+'/';
let redirectUrl = 'https://mawaheb.online/appleCallback';
if(domain == 'https://staging.mawaheb.online/' || domain == 'https://staging.mawaheb.online'){
  redirectUrl = 'https://staging.mawaheb.online/appleCallback';
}
// console.log('apple redirect -> ',redirectUrl);

  const AppleLoginBtn = (props) =>{

    const Auth = useContext(AuthContext);
    const Lang = useContext(UserContext);
    const navigate = useNavigate();



    const appleCallback = response => {

      // console.log('callback from apple');
      // console.log(response);
      // {
      //   "authorization": {
      //     "state": "[STATE]",
      //     "code": "[CODE]",
      //     "id_token": "[ID_TOKEN]"
      //   },
      //   "user": {
      //     "email": "[EMAIL]",
      //     "name": {
      //       "firstName": "[FIRST_NAME]",
      //       "lastName": "[LAST_NAME]"
      //     }
      //   }
      // }

        props.setLoading(true);
        const id_token = response?.authorization?.id_token;
        const code = response?.authorization?.code;
        if(id_token){
          const dataObj = {id_token,getEmailOnly: props?.getEmailOnly?1:0 };
        
          Auth.authorizedRequest({'Content-Type':'application/x-www-form-urlencoded'}).post('checkAppleLogin',dataObj).then(res => {   
            //console.log(res.data.payload);
            props.setLoading(false);

            if(props?.registerCalback && props?.getEmailOnly){
              props.registerCalback(res.data.payload?.email ||'',id_token || '');
              return;
            }              

            if(res.data.payload?.action == 'register'){  
              window.$('#login').modal('hide');   


              if(props?.registerCalback){
                  props.registerCalback(res.data.payload?.email ||'', id_token || '');
              }
              else{

                if(props?.preRedirect){
                  props.preRedirect();
                  setTimeout(() => {
                    navigate('/register/'+uuid(),{ state: res.data.payload});            
                  }, 2000);                   
                }
                else{
                  navigate('/register/'+uuid(),{ state: res.data.payload});            
                }  
         
              }
              
            }
            else if(res.data.payload?.action == 'login'){
              if(props?.preRedirect){
                props.preRedirect();
                setTimeout(() => {
                  Auth.successLoginProcess(res, props?.successLoginRedirect || '')  
                }, 2000);                 
              }  
              else{            
                Auth.successLoginProcess(res, props?.successLoginRedirect || '')
              }
              
              //console.log(res.data.payload)
            }
          }).catch(err => {
            props.setLoading(false);
          })                   
        }
      }
      //flow: 'auth-code',
    
    return (
      //  <AppleLogin usePopup={true} responseMode={`query`} callback={appleCallback} clientId={appleConfig.CLIENTID} redirectURI={`https://mawaheb.online/appleCallback`}     render={renderProps => (  //Custom Apple Sign in Button

// <div className={`social-login-container`} onClick={renderProps.onClick}>
// <div className='reg-button gray-bordered social-reg'>
// <FontAwesomeIcon style={{fontSize:'30px'}} icon={faApple} />
// <p>{`Continue with Apple`}</p>
// </div>
// </div>

//         )} />     
<>
<AppleSignin
/** Auth options passed to AppleID.auth.init() */
authOptions={{
  /** Client ID - eg: 'com.example.com' */
  clientId: appleConfig.CLIENTID,
  /** Requested scopes, seperated by spaces - eg: 'email name' */
  scope: 'email name',
  /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
  redirectURI: redirectUrl,
  /** State string that is returned with the apple response */
  state: 'state',
  /** Nonce */
  nonce: 'nonce',
  /** Uses popup auth instead of redirection */
  usePopup: true,
}} // REQUIRED
/** General props */
uiType="dark"
/** className */
className="apple-auth-btn"
/** Removes default style tag */
noDefaultStyle={false}
/** Allows to change the button's children, eg: for changing the button text */
buttonExtraChildren="Continue with Apple"
/** Extra controlling props */
/** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
onSuccess={appleCallback} // default = undefined
/** Called upon signin error */
onError={(error) => console.error(error)} // default = undefined
/** Skips loading the apple script if true */
skipScript={false} // default = undefined
/** Apple image props */
iconProp={{ style: { marginTop: '10px' } }} // default = undefined
/** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
render={(renderProps) => 
(
<div id={props?.id || 'apple-btn'} className={`social-login-container`} onClick={renderProps.onClick}>
<div className='reg-button gray-bordered social-reg'>
<FontAwesomeIcon style={{fontSize:'30px'}} icon={faApple} />
<p>{Lang.using_apple}</p>
</div>
</div>
)}  
/>
</>
    )
  }

export default AppleLoginBtn;